
import { useContext, useEffect, useState } from "react";
import ChangePassword from "../../components/popUps/ChangePassword";
import Header from "../../components/common/Header";
import toast from "react-hot-toast";
import { changePasswordRequest, getProfileData, updateCustomerData, updateProfile } from "../../config/api";
import { useApplication } from "../../test/useApplication";
import Loading from "../../components/common/Loading";
import AppContext from "../../context/AppProvider";
import PageLoading from "../../components/common/PageLoading";

const Setting = () => {
  const { profileData ,  setProfileData } = useContext(AppContext);

  const [changePassword, setChangePassword] = useState(false);
  const { app } = useApplication();
  const [data, setData] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    firstName: "",
    lastName: "",
    number: "",
    email: "",
  });
  const [selectedImage, setSelectedImage] = useState<any>();
  const defaultImage = "images/img_placeholder.png";
  const [loading, setIsLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [passwordLoading, setPasswordIsLoading] = useState<boolean>(false);

  const  {firstName , lastName , number } = formData


const isButtonShow =
  firstName !== profileData?.firstName ||
  lastName !== profileData?.lastName ||
  number !== profileData?.number ||
  (typeof selectedImage === "object" && selectedImage !== null);


  useEffect(()=>{
    const getData =async  ()=>{
      try {
        const res:any = await getProfileData()
        setProfileData(res?.data)
      } catch (error) {
        console.log("error" , error)
      }
    }
    getData()

  },[])
 

  useEffect(() => {
      if(profileData){
          setFormData({
              firstName: profileData?.firstName,
              lastName: profileData?.lastName,
              number: profileData?.number,
              email: profileData?.email,
          })
          setSelectedImage(profileData?.image)
      }
     
  }, [profileData])

  const handleClose = () => {
    setChangePassword(false);
  };


  const handleChange = (e: any) => {
    const {name , value} = e.target;
    if (name == "number") {
      if (value.length > 10) return
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

   //* profile change 
   const handleImage = (event: any) => {
    const file = event.target?.files[0]
    if (file) {
        setSelectedImage(file)
        
    }
}


  const passwordHandler = async (data:any)=>{
    try {
        
        setPasswordIsLoading(true)
      const res:any =  await changePasswordRequest(data)
      if(res?.status){
        setChangePassword(false)
        setPasswordIsLoading(false)
        toast.success("Password updated successfully")
      }
  
      } catch (error:any) {
        toast.error(error?.message)
        setPasswordIsLoading(false)
        console.log("error" , error)
      }
}


const handleSubmit = async ()=>{
  if( !formData.firstName || !formData.lastName || !formData.number || !selectedImage){
      return toast.error("All fields required!")
  }
  setIsLoading(true)
  try {
      const formData = new FormData()
      formData.append("firstName" , firstName)
      formData.append("lastName" , lastName)
      formData.append("number" , number)
   
      if(typeof selectedImage == "string"){
          formData.append("oldImageUrl" , selectedImage)
      }
      if( typeof selectedImage === "object"){
          formData.append("newCustomerImage" , selectedImage)
      }
      const res = await updateCustomerData(formData)
      const response:any = await getProfileData()
      setProfileData(response?.data)

      setIsLoading(false)
      
  } catch (error) {
      setIsLoading(false)
      toast.error("Something went wrong")
      console.log("error" , error)
  }

}

  return (
    <>
      {changePassword && (
        <ChangePassword
          loading={passwordLoading}
          handleClose={handleClose}
          passwordHandler={passwordHandler}
        />
      )}

      <Header
        title={"Settings"}
        backButton={null}
        backHandle={null}
        admin={true}
      />
      <div className="loaction-list-main">
        <div className="setting-main">
          {pageLoading ? (
            <PageLoading />
          ) : (
            <div className="setting-details">
              <h2>Basic Details</h2>
              <p>
                view & manage your basic profile details for the admin account
              </p>
              <div className="basic-detail">
                <div className="profile-upload">
                  <div className="profile-img">
                    <img
                      src={
                        selectedImage == null || selectedImage == ""
                          ? defaultImage
                          : typeof selectedImage === "object"
                          ? URL.createObjectURL(selectedImage)
                          : selectedImage
                      }
                      alt=""
                    />
                    <div className="input-file">
                      <input type="file" onChange={handleImage} />
                      <img src="images/setting_edit_icon.svg" alt="" />
                    </div>
                  </div>
                  <label>Update Picture</label>
                </div>
                <div className="profile-details">
                  <div className="input-group">
                    <div className="input-field">
                      <label htmlFor="">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="First Name"
                      />
                    </div>
                    <div className="input-field">
                      <label htmlFor="">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="input-field">
                      <label htmlFor="">Email</label>
                      <input
                        type="email"
                        name="email"
                        disabled
                        value={formData.email}
                        placeholder="abcde@gmail.com"
                        id=""
                      />
                    </div>
                    <div className="input-field">
                      <label htmlFor="">Phone Number</label>
                      <input
                        type="number"
                        name="number"
                        value={formData.number}
                        onChange={handleChange}
                        placeholder="+91 1234567890"
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="input-field">
                      <label htmlFor="">Password</label>
                      <div className="password">
                        <input
                          type="password"
                          name=""
                          disabled
                          placeholder="xxxxxxxxxxxxx"
                          id=""
                        />
                        <button
                          className="password-change-btn"
                          onClick={() => setChangePassword(true)}
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </div>
                                        <div className="save-btn">
                      {isButtonShow && (
                        <button onClick={handleSubmit} disabled={loading}>
                          {loading ? <Loading /> : "Save"}
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Setting;
