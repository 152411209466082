import { SetStateAction, useContext, useEffect, useRef, useState } from "react";
import Header from "../components/common/Header";
import { CarriewHead, ModelHead, planHead, PortHead } from "../utils/common";
import {
  addExcelSalesEntry,
  addSalesEntry,
  addSKUSalesEntry,
  deletePort,
  getPlanListing,
  getSalesEntryDetail,
  updateSalesEntryDetail,
} from "../config/api";
import Pagination from "../components/common/Pagination";
import { useNavigate } from "react-router-dom";
import PageLoading from "../components/common/PageLoading";
import AppContext from "../context/AppProvider";
import Loading from "../components/common/Loading";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { Table } from "semantic-ui-react";
import DraggableTableRow from "./DraggableTableRow";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import CustomDataTable from "./CustomDataTable";
import { Link } from "react-router-dom";
import { ClassNames } from "@emotion/react";

interface Plan {
  name: string;
  code: string;
  value: number;
  type: string;
}

interface Item {
  id: string;
  Plan: Plan;
  notes: string;
}

const SalesEntry = () => {
  const [addCarrier, setAddCarrier] = useState(false);
  const [editCarrier, setEditCarrier] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [planList, setPlanList] = useState<any>([]);
  const [swapIds, setSwapIds] = useState<any>(null);
  const [planDropDown, setPlanDropDown] = useState<any>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [data, setData] = useState<Item[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState([]);
  const [selectedSKUFile, setSelectedSKUFile] = useState<File | null>(null);
  const gridStyle = { minHeight: 550, width: "100vw" };
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [sortBy, setSortBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("ascending");
  const [prevType, setPrevType] = useState<string | null>(null);
  const [params, setParams] = useState({
    page: 1,
    perPage: 10,
  });
  const [portData, setPortData] = useState<any[]>([]);
  const [planData, setPlanData] = useState<any[]>([]);
  const [modelData, setModelData] = useState<any[]>([]);
  const [carrierData, setCarrierData] = useState<any[]>([]);
  const [isViewOnly, setIsViewOnly] = useState(false);

  const { activeIndex, setActiveIndex, setSalesId, salesId } =
    useContext(AppContext);
  const items = ["Plan", "Model/SKU", "Carrier", "Port"];
  const navigate = useNavigate();
  const pageHandler = (e: any) => {
    const page = e.selected + 1;
    setParams({ ...params, page });
  };

  const PortHeadColumns = PortHead.map((col) => ({
    name: col.label.toLowerCase().replace(/ /g, "_"),
    header: col.label,
    defaultWidth: 250,
    minWidth: 250,
    resizable: true,
    dataIndex: col.label.toLowerCase().replace(/ /g, "_"),
  }));

  const planHeadColumns = planHead.map((col) => ({
    name: col.label.toLowerCase().replace(/ /g, "_"),
    header: col.label,
    visible: true,
    defaultWidth: 220,
    resizable: true,
    minWidth: 220,
    dataIndex: col.label.toLowerCase().replace(/ /g, "_"),
  }));

  const ModelHeadColumns = ModelHead.map((col) => ({
    name: col.label.toLowerCase().replace(/ /g, "_"),
    header: col.label,
    defaultWidth: 150,
    minWidth: 150,
    resizable: true,
    dataIndex: col.label.toLowerCase().replace(/ /g, "_"),
  }));

  const CarriewHeadColumns = CarriewHead.map((col) => ({
    name: col.label.toLowerCase().replace(/ /g, "_"),
    header: col.label,
    defaultWidth: 800,
    minWidth: 700,
    resizable: true,
    dataIndex: col.label.toLowerCase().replace(/ /g, "_"),
  }));

  let actionItem = {
    name: "action new",
    header: "Action",
    defaultWidth: 280,
    dataIndex: "action".toLowerCase().replace(/ /g, "_"),
    resizable: true,
    minWidth: 100,
    visible: true,
  };

  let actionItemCarrier = {
    name: "action new",
    header: "Action",
    defaultWidth: 480,
    dataIndex: "action".toLowerCase().replace(/ /g, "_"),
    resizable: true,
    minWidth: 100,
    visible: true,
  };
  planHeadColumns.push(actionItem);
  CarriewHeadColumns.push(actionItemCarrier);
  ModelHeadColumns.push(actionItem);
  PortHeadColumns.push(actionItem);

  const handlePageSelect = (value: number) => {
    setParams({ ...params, perPage: value });
  };

  const fetchListing = async (resetFilters = false, resetPage = false) => {
    setIsLoading(true);
    try {
      const type = items[activeIndex];
      const effectiveFilters = resetFilters ? {} : filters;
      const effectivePage = resetPage ? 1 : params.page;
      const response: any = await getPlanListing(
        effectivePage,
        params.perPage,
        sortBy,
        sortOrder,
        type,
        effectiveFilters
      );
      setPlanList(response.data || []);
      if (type === "Port") {
        const portEntries: any[] = response.data.salesEntires || [];

        const flattenedPortData = portEntries.map((entry) => ({
          ...entry,
          ...entry.Port,
          start_date: moment(entry.Port.startDate).format("MM/DD/YYYY"),
          end_date: moment(entry.Port.endDate).format("MM/DD/YYYY"),
        }));

        setPortData(flattenedPortData);
      } else if (type === "Plan") {
        const planEntries: any[] = response.data.salesEntires || [];
        console.log("=============planEntries================", planEntries);

        const flattenedPlanData = planEntries.map((entry) => ({
          ...entry,
          ...entry.Plan,
          start_date: moment(entry.Plan.startDate).format("MM/DD/YYYY"),
          end_date: moment(entry.Plan.endDate).format("MM/DD/YYYY"),
        }));

        console.log(
          "=============flattenedPlanData==============",
          flattenedPlanData
        );
        setPlanData(flattenedPlanData);
      } else if (type === "Model/SKU") {
        const modelEntries: any[] = response.data.salesEntires || [];

        const flattenedModelData = modelEntries.map((entry) => ({
          ...entry,
          ...entry["Model/SKU"],
          start_date: moment(entry["Model/SKU"].startDate).format("MM/DD/YYYY"),
          end_date: moment(entry["Model/SKU"].endDate).format("MM/DD/YYYY"),
          common_name: entry["Model/SKU"].commonName,
          product_id: entry["Model/SKU"].productId,
        }));

        setModelData(flattenedModelData);
      } else if (type === "Carrier") {
        const carrierEntries: any[] = response.data.salesEntires || [];

        const flattenedCarrierData = carrierEntries.map((entry) => ({
          ...entry,
          ...entry.Carrier,
          plan_dropdown: entry.Carrier?.planDropDown,
        }));

        setCarrierData(flattenedCarrierData);
      } else {
        const result: Item[] = response.data.salesEntries || [];
        setData(result);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching plan listing:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const type = items[activeIndex];

    if (type !== prevType) {
      setFilters({});
      setSortBy("");
      setSortOrder("");
      fetchListing(true, true);
    } else {
      fetchListing(false, false);
    }

    setPrevType(type);
  }, [activeIndex, params, filters]);

  useEffect(() => {
    fetchListing();
  }, [sortOrder, sortBy]);

  const getHeaderTitle = () => {
    const type = items[activeIndex];

    switch (type) {
      case "Plan":
        return "Plan";
      case "Model/SKU":
        return "Model/SKU";
      case "Carrier":
        return "Carrier";
      case "Port":
        return "Port";
      default:
        return "Plan";
    }
  };

  const handleClickAddButton = () => {
    if (items[activeIndex] === "Carrier") {
      setAddCarrier(true);
      setPlanDropDown("");
    } else if (items[activeIndex] === "Plan") {
      navigate("/sales/add-plan");
    } else if (items[activeIndex] === "Model/SKU") {
      navigate("/sales/add-model");
    } else {
      navigate("/sales/add-port");
    }
  };

  const handleClickViewButton = (id: string) => {
    setSalesId(id);
    if (items[activeIndex] === "Carrier") {
      const selectedCarrier = carrierData.find((carrier) => carrier.id === id);
      if (selectedCarrier) {
        setPlanDropDown(selectedCarrier.plan_dropdown || "");
      }
      setIsViewOnly(true);
      setAddCarrier(true);
    } else if (items[activeIndex] === "Plan") {
      navigate("/sales/view-plan");
    } else if (items[activeIndex] === "Model/SKU") {
      navigate("/sales/view-model");
    } else {
      navigate("/sales/view-port");
    }
  };

  const handleClickEditButton = (id: string) => {
    setSalesId(id);
    if (items[activeIndex] === "Carrier") {
      const selectedCarrier = carrierData.find((carrier) => carrier.id === id);
      if (selectedCarrier) {
        setPlanDropDown(selectedCarrier.plan_dropdown || "");
      }
      setEditCarrier(true);
    } else if (items[activeIndex] === "Plan") {
      navigate("/sales/edit-plan");
    } else if (items[activeIndex] === "Model/SKU") {
      navigate("/sales/edit-model");
    } else {
      navigate("/sales/edit-port");
    }
  };

  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();
    if (!planDropDown) {
      return false;
    } else {
      setIsButtonLoading(true);
      try {
        const data = {
          carrier: {
            planDropDown: planDropDown.trim(),
          },
          type: "Carrier",
        };
        const response: any = await updateSalesEntryDetail(salesId, data);
        setIsButtonLoading(false);
        setEditCarrier(false);
        setPlanDropDown("");
        fetchListing();
      } catch (error: any) {
        toast.error(error.message);
        setEditCarrier(true);
        console.error("Error updating sales entry:", error);
        setIsButtonLoading(false);
      }
    }
  };

  const handleSubmitAdd = async () => {
    if (!planDropDown) {
      return false;
    } else {
      setIsButtonLoading(true);
      try {
        const data = {
          carrier: {
            planDropDown: planDropDown.trim(),
          },
          type: "Carrier",
        };
        const response: any = await addSalesEntry(data);
        setIsButtonLoading(false);
        setAddCarrier(false);
        setPlanDropDown("");
        fetchListing();
      } catch (error: any) {
        toast.error(error.message);
        console.error("Error updating sales entry:", error.message);
        setPlanDropDown("");
        // setAddCarrier(false);
        setIsButtonLoading(false);
      }
    }
  };

  const handleSortChange = (newSortBy: string, currentSortOrder: string) => {
    setSortBy((prevSortBy) => {
      if (prevSortBy === newSortBy) {
        setSortOrder((prevSortOrder) =>
          prevSortOrder === "ascending" ? "descending" : "ascending"
        );
      } else {
        setSortOrder("ascending");
      }
      return newSortBy;
    });
  };

  const handleDeleteOpen = (id: string) => {
    setDeletePopup(true);
    setSalesId(id);
  };

  const handleDeletePort = async () => {
    setIsDeleteButtonLoading(true);
    try {
      const response: any = await deletePort(salesId);
      setIsDeleteButtonLoading(false);
      setDeletePopup(false);
      fetchListing();
    } catch (error: any) {
      toast.error(error?.message);
      console.error("Error updating sales entry:", error);
      setIsDeleteButtonLoading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
  };

  const handleSKUFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedSKUFile(file);
  };

  const handleExcelData = async () => {
    if (!selectedFile) {
      toast.success("Select a file to add plan.");
      return;
    }
    setFileLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const response: any = await addExcelSalesEntry(formData);
      setSelectedFile(null);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      toast.success(response?.message);
      setFileLoading(false);
      fetchListing();
    } catch (error: any) {
      console.error("Error uploading file:", error);
      setErrorMessage(error?.errors);
      setErrorPopup(true);
      setMessage(error?.message);
      setFileLoading(false);
    }
  };

  const handleSKUExcelData = async () => {
    if (!selectedSKUFile) {
      toast.success("Select a file to add SKU.");
      return;
    }
    setFileLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedSKUFile);

      const response: any = await addSKUSalesEntry(formData);
      setSelectedSKUFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      toast.success(response?.message);
      setFileLoading(false);
      fetchListing();
    } catch (error: any) {
      console.error("Error uploading file:", error);
      setErrorMessage(error?.errors);
      setMessage(error?.message);
      setErrorPopup(true);
      setFileLoading(false);
    }
  };

  const fetchDetail = async () => {
    try {
      const response: any = await getSalesEntryDetail(salesId);
      setPlanDropDown(response?.data?.carrier?.planDropDown);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching sales entry detail:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchDetail();
  }, [salesId]);

  useEffect(() => {
    fetchDetail();
  }, [data]);

  const trimToAlphabets = (name: string): string => {
    return name.replace(/[^a-zA-Z]/g, "").slice(0, 10);
  };

  const swap = (a: number, b: number) => {
    setData((prevItems: Item[]) => {
      const updatedItems = [...prevItems];
      // Swap the items at indices a and b
      updatedItems[a] = updatedItems.splice(b, 1, updatedItems[a])[0];
      // console.log(
      //   `Swapped item stringify ----------------: ${JSON.stringify(
      //     updatedItems[a].id
      //   )} <=> ${JSON.stringify(updatedItems[b].id)}`
      // );
      setSwapIds({ firstId: updatedItems[a].id, secondId: updatedItems[b].id });
      return updatedItems;
    });
  };

  // useEffect(() => {
  //   const swappedItems = async () => {
  //     try {
  //       const response: any = await swapSalesEntries(
  //         swapIds?.firstId,
  //         swapIds?.secondId
  //       );
  //       // console.log("Swapped items: ", response);
  //       fetchListing();
  //     } catch (error) {
  //       console.error("Error swapping items:", error);
  //     }
  //   };

  //   swappedItems();
  // }, [swapIds]);

  // const handleChangeIndex = (index: any) => {
  //   setActiveIndex(index);
  //   setSelectedFile(null)
  // };

  return (
    <>
      <Header
        title={getHeaderTitle()}
        backButton={false || null}
        backHandle={() => {
          setPlanList(null);
        }}
        admin={true}
      />
      <Toaster />
      <div className="loaction-list-main">
        <div className="loaction-list-head incident-select-head sales-entry-head">
          <ul className="incident-select">
            {items?.map((item, index) => (
              <li
                key={index}
                className={activeIndex === index ? "active" : ""}
                onClick={() => {
                  setActiveIndex(index);
                  setSelectedFile(null);
                }}
              >
                {item}
              </li>
            ))}
          </ul>
          <div className="file-button">
            {activeIndex === 0 && (
              <>
                <div className="file-input">
                  <input
                    type="file"
                    className="add-excel-file"
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                  {selectedFile ? (
                    <div className="selected-file-container">
                      <span>{trimToAlphabets(selectedFile.name)}</span>
                    </div>
                  ) : (
                    "Upload Plans"
                  )}
                  {selectedFile && (
                    <div className="cross-image">
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedFile(null);
                          if (fileInputRef.current) {
                            fileInputRef.current.value = "";
                          }
                        }}
                        src="images/times_icon.svg"
                        alt=""
                      />
                    </div>
                  )}
                </div>

                {selectedFile && (
                  <button
                    className="upload-file_button"
                    onClick={handleExcelData}
                  >
                    {fileLoading ? (
                      <div className="upload_loader"></div>
                    ) : (
                      "Upload Plan"
                    )}
                  </button>
                )}
              </>
            )}
            {activeIndex === 1 && (
              <>
                <div className="file-input">
                  <input
                    type="file"
                    className="add-excel-file"
                    accept=".xlsx, .xls"
                    onChange={handleSKUFileChange}
                    ref={fileInputRef}
                  />
                  {selectedSKUFile
                    ? trimToAlphabets(selectedSKUFile.name)
                    : "Upload Model/SKU"}
                  {selectedSKUFile && (
                    <div className="cross-image">
                      <img
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedSKUFile(null);
                          if (fileInputRef.current) {
                            fileInputRef.current.value = "";
                          }
                        }}
                        src="images/times_icon.svg"
                        alt=""
                      />
                    </div>
                  )}
                </div>
                {selectedSKUFile && (
                  <button
                    className="upload-file_button"
                    onClick={handleSKUExcelData}
                  >
                    {fileLoading ? (
                      <div className="upload_loader"></div>
                    ) : (
                      "Upload SKU"
                    )}
                  </button>
                )}{" "}
              </>
            )}
            <button className="add-service" onClick={handleClickAddButton}>
              + Add {items[activeIndex]}
            </button>
          </div>
        </div>
        <div className="table-data-main sales-entry-table-head">
          <div className="table-data">
            <style>{`
          .draggable {
            cursor: move;
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
          }
        `}</style>
            <Table border={0} cellPadding={0} style={{ width: "100%" }}>
              <Table.Header
                style={{
                  backgroundColor: "#fdfdfd",
                  borderBottom: "1px solid #ededed",
                  borderTop: "1px solid #ededed",
                }}
              >
                <Table.Row>
                  {activeIndex === 0 && (
                    <CustomDataTable
                      dataSource={planData}
                      visibleColumns={planHeadColumns}
                      filters={filters}
                      setFilters={setFilters}
                      onSortChange={(
                        columnKey: string,
                        currentSortOrder: string
                      ) => handleSortChange(columnKey, currentSortOrder)}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                      fetchSalesListing={fetchListing}
                      selectedSalesType={getHeaderTitle()}
                      handleClickViewButton={handleClickViewButton}
                      handleClickEditButton={handleClickEditButton}
                      handleDeleteOpen={handleDeleteOpen}
                    />
                  )}

                  {activeIndex === 1 && (
                    <CustomDataTable
                      dataSource={modelData}
                      visibleColumns={ModelHeadColumns}
                      filters={filters}
                      setFilters={setFilters}
                      onSortChange={(
                        columnKey: string,
                        currentSortOrder: string
                      ) => handleSortChange(columnKey, currentSortOrder)}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                      fetchSalesListing={fetchListing}
                      selectedSalesType={getHeaderTitle()}
                      handleClickViewButton={handleClickViewButton}
                      handleClickEditButton={handleClickEditButton}
                      handleDeleteOpen={handleDeleteOpen}
                    />
                  )}
                  {activeIndex === 2 && (
                    <CustomDataTable
                      dataSource={carrierData}
                      visibleColumns={CarriewHeadColumns}
                      filters={filters}
                      setFilters={setFilters}
                      onSortChange={(
                        columnKey: string,
                        currentSortOrder: string
                      ) => handleSortChange(columnKey, currentSortOrder)}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                      fetchSalesListing={fetchListing}
                      selectedSalesType={getHeaderTitle()}
                      handleClickViewButton={handleClickViewButton}
                      handleClickEditButton={handleClickEditButton}
                      handleDeleteOpen={handleDeleteOpen}
                    />
                  )}
                  {activeIndex === 3 && (
                    <CustomDataTable
                      dataSource={portData}
                      visibleColumns={PortHeadColumns}
                      filters={filters}
                      setFilters={setFilters}
                      onSortChange={(
                        columnKey: string,
                        currentSortOrder: string
                      ) => handleSortChange(columnKey, currentSortOrder)}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                      fetchSalesListing={fetchListing}
                      selectedSalesType={getHeaderTitle()}
                      handleClickViewButton={handleClickViewButton}
                      handleClickEditButton={handleClickEditButton}
                      handleDeleteOpen={handleDeleteOpen}
                    />
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {isLoading ? (
                  <tr>
                    <td colSpan={100}>
                      <div
                        className="sales-loader"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "10vh",
                        }}
                      >
                        <PageLoading />
                      </div>
                    </td>
                  </tr>
                ) : planList?.salesEntires?.length === 0 ? (
                  <tr>
                    <td colSpan={100}>
                      <div
                        className="sales-loader"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "10vh",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "10vh",
                          }}
                        >
                          <img src="/images/sales-entry.png" alt="" />
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "600",
                              marginTop: "10px",
                            }}
                          >{`No ${items[activeIndex]} added Yet`}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  data?.map((item: any, idx: any) => {
                    if (item?.type === "Plan") {
                      return (
                        <DraggableTableRow key={idx} i={idx} action={swap}>
                          <Table.Cell>{item?.Plan?.name}</Table.Cell>
                          <Table.Cell>
                            {item?.Plan?.code.toUpperCase()}
                          </Table.Cell>
                          <Table.Cell>{"$" + item?.Plan?.value}</Table.Cell>
                          <Table.Cell>{item?.Plan?.type}</Table.Cell>
                          <Table.Cell>
                            {moment(item?.Plan?.startDate).format("MM/DD/YYYY")}
                          </Table.Cell>
                          <Table.Cell>
                            {item?.Plan?.endDate
                              ? moment(item?.Plan?.endDate).format("MM/DD/YYYY")
                              : "-"}
                          </Table.Cell>
                          <Table.Cell>
                            <div className="sales-action">
                              <button
                                className="action-btn"
                                onClick={() => handleClickViewButton(item?.id)}
                              >
                                <img src="images/action_eye.svg" alt="View" />
                              </button>
                              <button
                                className="action-btn"
                                onClick={() => handleClickEditButton(item?.id)}
                              >
                                <img
                                  src="images/action_edit_new.svg"
                                  alt="Edit"
                                />
                              </button>
                              <button
                                className="action-btn"
                                onClick={() => handleDeleteOpen(item?.id)}
                              >
                                <img
                                  src="images/delete_icon_black.svg"
                                  alt="Delete"
                                />
                              </button>
                            </div>
                          </Table.Cell>
                        </DraggableTableRow>
                      );
                    } else if (item?.type === "Model/SKU") {
                      return (
                        <tr
                          key={idx}
                          style={{ borderBottom: "1px solid #ededed" }}
                        >
                          <td className="table-col">
                            {item?.["Model/SKU"]?.commonName}
                          </td>
                          <td className="table-col">
                            {item?.["Model/SKU"]?.name}
                          </td>
                          <td className="table-col">
                            {item?.["Model/SKU"]?.sku}
                          </td>
                          <td className="table-col">
                            {item?.["Model/SKU"]?.upc}
                          </td>
                          <td className="table-col">
                            {item?.["Model/SKU"]?.productId}
                          </td>
                          <td className="table-col">
                            {item?.["Model/SKU"]?.kitt}
                          </td>
                          <td className="table-col">
                            {item?.["Model/SKU"]?.type}
                          </td>
                          <Table.Cell>
                            {moment(item?.["Model/SKU"]?.startDate).format(
                              "MM/DD/YYYY"
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {moment(item?.["Model/SKU"]?.endDate).format(
                              "MM/DD/YYYY"
                            )}
                          </Table.Cell>
                          <td
                            style={{ textAlign: "center" }}
                            className="table-col"
                          >
                            <div className="sales-action">
                              <button
                                className="action-btn"
                                onClick={() => handleClickViewButton(item?.id)}
                              >
                                <img src="images/action_eye.svg" alt="View" />
                              </button>
                              <button
                                className="action-btn"
                                onClick={() => handleClickEditButton(item?.id)}
                              >
                                <img
                                  src="images/action_edit_new.svg"
                                  alt="Edit"
                                />
                              </button>
                              <button
                                className="action-btn"
                                onClick={() => handleDeleteOpen(item?.id)}
                              >
                                <img
                                  src="images/delete_icon_black.svg"
                                  alt="Delete"
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    } else if (item?.type === "Carrier") {
                      return (
                        <DraggableTableRow key={idx} i={idx} action={swap}>
                          <Table.Cell>{item?.Carrier?.planDropDown}</Table.Cell>
                          <Table.Cell>
                            <div className="sales-action">
                              <button
                                className="action-btn"
                                onClick={() => handleClickEditButton(item?.id)}
                              >
                                <img
                                  src="images/action_edit_new.svg"
                                  alt="Edit"
                                />
                              </button>
                              <button
                                className="action-btn"
                                onClick={() => handleDeleteOpen(item?.id)}
                              >
                                <img
                                  src="images/delete_icon_black.svg"
                                  alt="Delete"
                                />
                              </button>
                            </div>
                          </Table.Cell>
                        </DraggableTableRow>
                      );
                    } else if (item?.type === "Port") {
                      return (
                        <DraggableTableRow key={idx} i={idx} action={swap}>
                          <Table.Cell>{item?.Port?.name}</Table.Cell>
                          <Table.Cell>{item?.Port?.type}</Table.Cell>
                          <Table.Cell>
                            {moment(item?.Port?.startDate).format("MM/DD/YYYY")}
                          </Table.Cell>
                          <Table.Cell>
                            {item?.Port?.endDate
                              ? moment(item?.Port?.endDate).format("MM/DD/YYYY")
                              : "TBD"}
                          </Table.Cell>
                          <Table.Cell>
                            <div className="sales-action">
                              <button
                                className="action-btn"
                                onClick={() => handleClickViewButton(item?.id)}
                              >
                                <img src="images/action_eye.svg" alt="View" />
                              </button>
                              <button
                                className="action-btn"
                                onClick={() => handleClickEditButton(item?.id)}
                              >
                                <img
                                  src="images/action_edit_new.svg"
                                  alt="Edit"
                                />
                              </button>
                              <button
                                className="action-btn"
                                onClick={() => handleDeleteOpen(item?.id)}
                              >
                                <img
                                  src="images/delete_icon_black.svg"
                                  alt="Delete"
                                />
                              </button>
                            </div>
                          </Table.Cell>
                        </DraggableTableRow>
                      );
                    }
                    return null;
                  })
                )}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>

      {planList.length !== 0 && (
        <Pagination
          paramsData={params}
          total={planList.total}
          pageCount={planList.totalPages}
          handlePageChange={pageHandler}
          handlePageSelect={handlePageSelect}
          increasePageSize={true}
        />
      )}

      {addCarrier && (
        <div className="carrier-overlay">
          <div className="add-carrier-main">
            <div className="head">
              <h3>Add Carrier</h3>
              <button onClick={() => setAddCarrier(false)}>
                <img src="images/times_icon.svg" alt="" />
              </button>
            </div>
            <div className="input-field">
              <label>Plan Dropdown</label>
              {isViewOnly ? (
                // Render a read-only value if viewing only
                <div className="view-value">{planDropDown}</div>
              ) : (
                <input
                  type="text"
                  placeholder="Total Wireless"
                  value={planDropDown}
                  onChange={(e) => setPlanDropDown(e.target.value)}
                />
              )}
            </div>
            <div className="btns">
              <button onClick={() => setAddCarrier(false)}>Cancel</button>
              {!isViewOnly && (
                <button className="save-btn" onClick={handleSubmitAdd}>
                  {isButtonLoading ? <Loading /> : "Save"}
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {editCarrier && (
        <div className="carrier-overlay">
          <div className="add-carrier-main">
            <div className="head">
              <h3>Edit Carrier</h3>
              <button onClick={() => setEditCarrier(false)}>
                <img src="images/times_icon.svg" alt="" />
              </button>
            </div>
            <div className="input-field">
              <label>Plan Dropdown</label>
              <input
                type="text"
                placeholder="Total Wireless"
                value={planDropDown}
                onChange={(e) => setPlanDropDown(e.target.value)}
              />
            </div>
            <div className="btns">
              <button onClick={() => setEditCarrier(false)}>Cancel</button>
              <button className="save-btn" onClick={handleSubmit}>
                {isButtonLoading ? <Loading /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      )}

      {errorPopup && (
        <div className="carrier-overlay">
          <div className="delete-main error-main">
            <button className="delete-icon">
              <img src="/images/error.svg" alt="" />
            </button>
            <h2>Error!</h2>
            <p>
              {errorMessage ? (
                <ul>
                  {errorMessage?.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              ) : (
                message
              )}
            </p>
            <div className="block-btn">
              <button
                className="logout-btn"
                onClick={() => setErrorPopup(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}

      {deletePopup && (
        <div className="carrier-overlay">
          <div className="delete-main">
            <button className="delete-icon">
              <img src="images/delete_icon.svg" alt="" />
            </button>
            <h2>Please Confirm!</h2>
            <p>Are you sure, you want to delete</p>
            <div className="block-btn">
              <button
                className="cancle-btn"
                onClick={() => setDeletePopup(false)}
              >
                Cancel
              </button>
              <button className="logout-btn" onClick={handleDeletePort}>
                {isDeleteButtonLoading ? <Loading /> : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SalesEntry;
