import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/common/Header";
import { useNavigate, useParams } from "react-router-dom";
import grammarIcon from "../../assets/svg/grammaricon.svg";

import {
  deleteSales,
  getAllCustomers,
  getSalesEntryDetails,
  getWebCarrier,
  getWebModel,
  getWebPlans,
  getWebPort,
  grammarPrompt,
  updateWebSalesEntry,
} from "../../config/api";
import PageLoading from "../../components/common/PageLoading";
import moment from "moment-timezone";
import { formatDate, formatDateNew } from "../../utils/common";
import toast from "react-hot-toast";
import { useBlocker } from "../../utils/useBlocker";
import Loading from "../../components/common/Loading";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface Customer {
  id: string;
  name: string;
}
function LineDetails() {
  const initialline = {
    autopay: false,
    carrier: {
      id: "",
      name: "",
    },
    customerId: "",
    displayedDate: "",
    id: "",
    idVerify: null,
    imei: "",
    lineNumber: NaN,
    plan: {
      id: "",
      name: "",
      value: "",
    },
    vpOrderNumber: "",
    modelSku: {
      id: "",
      name: "",
    },
    monthly: "",
    notes: "",
    number: "",
    pins: "",
    port: {
      id: "",
      name: "",
    },
    sim: "",
    today: "",
    type: "",
  };

  const [lines, setLines] = useState([initialline]);
  const [customerString, setCustomerString] = useState<string>("");
  const [grammarLoading, setGrammarLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<number>();
  const [searchString, setSearchString] = useState<string>("");
  const [customerDropDown, setCustomerDropdown] = useState(false);
  const [removeIds, setRemoveIds] = useState<string[]>([]);
  const [planSearchString, setPlanSearchString] = useState<string>("");
  const [portSearchString, setPortSearchString] = useState<string>("");
  const [carrierSearchString, setCarrierSearchString] = useState<string>("");
  const [activeName, setActiveName] = useState<string>("");
  const [carriers, setCarriers] = useState<any[]>([]);
  const [models, setModels] = useState<any[]>([]);
  const [plans, setPlans] = useState<any[]>([]);
  const [ports, setPorts] = useState<any[]>([]);
  const planInputRef = useRef<HTMLInputElement | null>(null);
  const portInputRef = useRef<HTMLInputElement | null>(null);
  const carrierInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const { id } = useParams();
  const [selectedCustomer, setSelectedCustomer] = useState<{
    id: string | null;
    name: string | null;
  }>({ id: null, name: null });
  const [deletePopup, setDeletePopup] = useState(false);
  const handleDeleteOpen = (id: string) => {
    setDeletePopup(true);
  };
  const handleDeletePort = async () => {
    setIsDeleteButtonLoading(true);
    try {
      const response: any = await deleteSales(id);
      setIsDeleteButtonLoading(false);
      setDeletePopup(false);
      navigate("/website-sales");
    } catch (error: any) {
      toast.error(error?.message);
      console.error("Error updating sales entry:", error);
      setIsDeleteButtonLoading(false);
    }
  };
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);

  const [customerName, setCustomerName] = useState("");
  const [addCustomer, setAddCustomer] = useState(false);
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [data, setData] = useState<any>(null);
  const [displayDate, setDisplayDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [dataType, setDataType] = useState("");
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleBlock = () => {
    if (isDirty) {
      return !window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
    }
    return false;
  };

  useBlocker(handleBlock, isDirty);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (data) {
      const newDate: any = data?.data[0].displayedDate;
      // const date = { formatDateNew(newDate) };
      setDisplayDate(newDate);
      setDataType(data?.data[0].type);
    }
  }, [data]);

  console.log("data?.data[0].displayedDate", data?.data[0].displayedDate);

  const getData = async () => {
    setLoading(true);
    try {
      if (id) {
        let res: any = await getSalesEntryDetails(id);
        setData(res);
        setLines(res?.data);
      } else {
        console.error("saleId is missing");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const formatDate = (date: any) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsDirty(false);
    setIsButtonLoading(true);
    try {
      const data = lines.map((line) => ({
        id: line.id ? line.id : null,
        lineNumber: parseInt(line.lineNumber.toString()) || 0,
        vpOrderNumber: parseInt(line.vpOrderNumber.toString()) || 0,
        autopay: line.autopay,
        carrier: line.carrier,
        idVerify: line.idVerify,
        imei: line.imei.trim(),
        modelSku: line.modelSku,
        monthly: parseFloat(line.monthly.toString()) || 0,
        notes: line.notes,
        number: line.number,
        pins: line.pins,
        plan: line.plan,
        port: line.port,
        sim: line.sim.trim(),
        today: parseFloat(line.today.toString()) || 0,
        type: dataType === "addALine" ? "addALine" : "newActivation",
      }));

      const dateToSend = {
        displayDate: formatDate(displayDate),
        newCustomer: {
          address: customerAddress ? customerAddress : null,
          email: customerEmail ? customerEmail : null,
          name: customerName ? customerName : null,
        },
        oldCustomerId: customerName ? null : selectedCustomer?.id,
        type: customerName != "" ? "newCustomer" : "oldCustomer",
        sales: data,
        removeSale: removeIds,
      };

      const response: any = await updateWebSalesEntry(id, dateToSend);
      navigate("/website-sales");
      setIsButtonLoading(false);
    } catch (error: any) {
      console.error("Error updating sales entry:", error);
      setIsButtonLoading(false);
      toast.error(error?.message);
    } finally {
      setIsButtonLoading(false);
      setIsDirty(true);
    }
  };

  const handleCustomerChange = (customer: { id: string; name: string }) => {
    // const selectedId = id;
    // const selectedCustomer = customers.find((customer) => customer.id === id);

    setSelectedCustomer(customer);
    setCustomerDropdown(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const carriersResponse: any = await getWebCarrier(carrierSearchString);
        setCarriers(carriersResponse.data || []);
        const modelsResponse: any = await getWebModel(searchString);
        setModels(modelsResponse.data || []);
        const plansResponse: any = await getWebPlans(planSearchString);
        setPlans(plansResponse.data || []);
        const portsResponse: any = await getWebPort(portSearchString);
        setPorts(portsResponse.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [searchString, planSearchString, portSearchString, carrierSearchString]);

  const handleGrammar = async () => {
    try {
      setGrammarLoading(true);
      const updatedLines = await Promise.all(
        lines.map(async (line) => {
          if (line?.notes) {
            try {
              const res: any = await grammarPrompt(line?.notes);
              if (res?.status) {
                return { ...line, notes: res?.message || line?.notes };
              }
            } catch (error) {
              console.error("Error processing line notes:", error);
              return line;
            }
          }
          return line;
        })
      );
      setLines(updatedLines);
    } catch (error) {
      console.error("Error in handleGrammar:", error);
    } finally {
      setGrammarLoading(false);
    }
  };

  const handleChange = (e: any, index: number) => {
    const { name, value, type, checked } = e.target;
    setActiveDropdown(-1);
    setActiveName("");

    const updatedLines: any = [...lines];

    if (name === "lineNumber" && !isNaN(Number(value))) {
      const newLineNumber = value === "" ? null : Number(value);

      updatedLines[index] = {
        ...updatedLines[index],
        [name]: newLineNumber,
      };

      for (let i = index + 1; i < updatedLines.length; i++) {
        updatedLines[i] = {
          ...updatedLines[i],
          lineNumber: updatedLines[i - 1].lineNumber + 1,
        };
      }
    } else if (name.includes(".")) {
      const [parent, child] = name.split(".");

      updatedLines[index] = {
        ...updatedLines[index],
        [parent]: {
          ...updatedLines[index][parent],
          [child]: type === "checkbox" ? checked : value,
        },
      };
    } else {
      updatedLines[index] = {
        ...updatedLines[index],
        [name]: type === "checkbox" ? checked : value,
      };
    }

    setLines(updatedLines);
    setIsDirty(true);
  };

  const validateForm = () => {
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (!line.lineNumber) {
        toast.error(`Line Number is required for line ${i + 1}.`);
        return false;
      }
      if (!line.number || line.number.trim() === "") {
        toast.error(`Phone Number is required for line ${i + 1}.`);
        return false;
      } else if (line.number.trim().length !== 10) {
        toast.error(
          `Phone Number must be exactly 10 digits for line ${i + 1}.`
        );
        return false;
      }
      if (!line.modelSku || line.modelSku.name.trim() === "") {
        toast.error(`Model SKU is required for line ${i + 1}.`);
        return false;
      }
      if (!line.imei) {
        toast.error(`IMEI is required for line ${i + 1}.`);
        return false;
      }
      if (!line.sim) {
        toast.error(`SIM is required for line ${i + 1}.`);
        return false;
      }
      if (!line.carrier || line.carrier.name?.trim() === "") {
        toast.error(`Carrier is required for line ${i + 1}.`);
        return false;
      }
      if (!line.port || line.port.name?.trim() === "") {
        toast.error(`Port is required for line ${i + 1}.`);
        return false;
      }
      if (isNaN(parseFloat(line.today)) || parseFloat(line.today) < 0) {
        toast.error(`Today's amount must be a valid number for line ${i + 1}.`);
        return false;
      }
      if (isNaN(parseFloat(line.monthly)) || parseFloat(line.monthly) < 0) {
        toast.error(`Monthly amount must be a valid number for line ${i + 1}.`);
        return false;
      }
      if (!line.vpOrderNumber) {
        toast.error(`VP Order Number is required for line ${i + 1}.`);
        return false;
      }
    }

    return true;
  };

  const handleOpenCustomerDropdown = () => {
    setCustomerDropdown(!customerDropDown);
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {
      try {
        const response: any = await getAllCustomers(customerString);

        setCustomers(response?.data);

        const customer = response?.data.find(
          (customer: any) => customer?.id === lines[0]?.customerId
        );

        console.log("Matched Customer:", customer);

        // setCustomerString("");
        if (customer) {
          setSelectedCustomer({
            id: customer.id,
            name: customer.name,
          });
        }
      } catch (error) {
        console.error("Error getting customers:", error);
      }
    }, 1000);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [data, customerString]);

  const handleOpenDropdown = (index: number, name: string) => {
    setActiveDropdown(index);
    setActiveName(name);
  };

  const handleOpenDropdownFocus = (index: number, name: string) => {
    if (activeDropdown !== index || activeName !== name) {
      setActiveDropdown(index);
      setActiveName(name);

      setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 0);
      setTimeout(() => {
        if (planInputRef.current) {
          planInputRef.current.focus();
        }
      }, 0);
      setTimeout(() => {
        if (portInputRef.current) {
          portInputRef.current.focus();
        }
      }, 0);
      setTimeout(() => {
        if (carrierInputRef.current) {
          carrierInputRef.current.focus();
        }
      }, 0);
    }
  };

  const addLine = () => {
    const lastLineNumber =
      lines.length > 0 ? Number(lines[lines.length - 1].lineNumber) : 0;
    const nextLineNumber = lastLineNumber + 1;
    const newLine = {
      ...initialline,
      lineNumber: nextLineNumber,
    };
    setLines((prevLines) => [...prevLines, newLine]);
  };

  const handleDateChange = (date: Dayjs | null) => {
    console.log("Received date:", date);
    const formattedDate = date ? dayjs(date).format("MM-DD-YYYY") : "";
    setDisplayDate(formattedDate);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setActiveDropdown(-1);
      setActiveName("");
      setCustomerDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div>
      <Header
        title={"Line Details"}
        backButton={true}
        backHandle={handleBack}
        admin={false}
      />
      {loading ? (
        <PageLoading />
      ) : (
        <>
          <div className="loaction-list-main add-plan-main activation-main">
            <div className="loaction-list-head">
              <div className="add-plan-detail" style={{ padding: "0px" }}>
                <div className="input-group new-customer">
                  {!addCustomer && (
                    <div className="input-field input-field-data">
                      <>
                        {/* <select
                          name="lineNumber"
                          value={selectedCustomer.id || ""}
                          onChange={handleCustomerChange}
                        >
                          <option value="">Select Customer</option>
                          {customers?.map((customer, index) => (
                            <option key={index} value={customer.id}>
                              {customer.name}{" "}
                            </option>
                          ))}
                        </select> */}

                        <div className="input-field input-field-data">
                          <label>
                            Select Customer <sup className="required">*</sup>
                          </label>
                          <button
                            type="button"
                            onClick={() => handleOpenCustomerDropdown()}
                          >
                            {selectedCustomer.name || "Select"}{" "}
                            <img
                              src="/images/angle_down_grey.svg"
                              alt="dropdown"
                            />
                          </button>
                          {customerDropDown && (
                            <div
                              className="carrier-search-main active"
                              ref={dropdownRef}
                            >
                              <div className="search-bar">
                                <input
                                  type="search"
                                  placeholder=""
                                  name="searchString"
                                  value={customerString}
                                  // ref={searchInputRef}
                                  onChange={(e) =>
                                    setCustomerString(e.target.value)
                                  }
                                />
                              </div>
                              <ul>
                                {customers?.map((customer) => (
                                  <li
                                    key={customer.id}
                                    onClick={() =>
                                      handleCustomerChange(customer)
                                    }
                                  >
                                    {customer.name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </>
                    </div>
                  )}

                  {addCustomer && (
                    <>
                      <div className="input-field">
                        <label>
                          Customer Name <sup className="required">*</sup>
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          value={customerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                          placeholder=""
                        />
                      </div>
                      <div className="input-field">
                        <label>
                          Customer Email <sup className="required">*</sup>
                        </label>
                        <input
                          type="email"
                          autoComplete="off"
                          value={customerEmail}
                          onChange={(e) => setCustomerEmail(e.target.value)}
                          name="lineNumber"
                          placeholder=""
                        />
                      </div>
                      <div className="input-field">
                        <label>
                          Customer Address <sup className="required">*</sup>
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          name="lineNumber"
                          placeholder=""
                          value={customerAddress}
                          onChange={(e) => setCustomerAddress(e.target.value)}
                        />
                      </div>
                    </>
                  )}

                  <div className="input-field">
                    <label>
                      Display Date <sup className="required">*</sup>
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        timezone={"UTC"}
                        value={dayjs.utc(displayDate)}
                        onChange={(date) => handleDateChange(date)}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <button
                  className="add-customer-button"
                  onClick={() => setAddCustomer(!addCustomer)}
                >
                  {addCustomer ? "Choose customer" : "Add New Customer"}
                </button>
              </div>
            </div>
          </div>
          {lines?.map((line: any, index: any) => (
            <div className="loaction-list-main add-plan-main activation-main">
              <div className="loaction-list-head location-list-data">
                <h3>Line {index + 1}</h3>
                {lines?.length === 1 ? (
                  <button
                    type="button"
                    className="remove-btn"
                    onClick={() => handleDeleteOpen(line?.saleId)}
                  >
                    Delete
                  </button>
                ) : (
                  <button
                    type="button"
                    className="remove-btn"
                    onClick={() => {
                      if (line?.id) {
                        setRemoveIds((prevIds) => [...prevIds, line.id]);
                      }
                      setLines(lines.filter((_, i) => i !== index));
                    }}
                  >
                    Remove
                  </button>
                )}
              </div>

              <form autoComplete="off" className="add-plan-detail">
                <div className="input-group" key={index}>
                  <div className="input-field">
                    <label>Line Number</label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="lineNumber"
                      placeholder=""
                      value={line?.lineNumber}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </div>
                  <div className="input-field">
                    <label>Plan</label>
                    <button
                      type="button"
                      onFocus={() => handleOpenDropdownFocus(index, "Plan")}
                      onClick={() => handleOpenDropdown(index, "Plan")}
                    >
                      {line.plan.value
                        ? `$${line.plan.value} - ${line.plan.name}`
                        : "Select"}
                      <img src="/images/angle_down_grey.svg" alt="dropdown" />
                    </button>
                    {activeDropdown === index && activeName === "Plan" && (
                      <div
                        className="carrier-search-main active"
                        ref={dropdownRef}
                      >
                        <div className="search-bar">
                          <input
                            type="search"
                            placeholder="Search Plan Value"
                            name="searchString"
                            value={planSearchString}
                            onChange={(e) =>
                              setPlanSearchString(e.target.value)
                            }
                            ref={searchInputRef}
                          />
                        </div>
                        <ul>
                          {plans.map((plan) => (
                            <li
                              key={plan.id}
                              onClick={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "plan",
                                      value: {
                                        id: plan.id,
                                        name: plan.name,
                                        value: plan.value,
                                      },
                                    },
                                  },
                                  index
                                )
                              }
                            >
                              ${plan.value} - {plan.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="input-field">
                    <label>
                      Phone Number<sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="number"
                      value={line.number}
                      onChange={(e) => handleChange(e, index)}
                      maxLength={10}
                      pattern="\d*"
                      onInput={(e) => {
                        const target = e.target as HTMLInputElement;
                        target.value = target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </div>

                  <div className="input-field">
                    <label>
                      Model/SKU <sup className="required">*</sup>
                    </label>
                    <button
                      type="button"
                      onFocus={() =>
                        handleOpenDropdownFocus(index, "Model/SKU")
                      }
                      onClick={() => handleOpenDropdown(index, "Model/SKU")}
                    >
                      {line.modelSku.name || "Select"}{" "}
                      <img src="/images/angle_down_grey.svg" alt="dropdown" />
                    </button>
                    {activeDropdown === index && activeName === "Model/SKU" && (
                      <div
                        className="carrier-search-main active"
                        ref={dropdownRef}
                      >
                        <div className="search-bar">
                          <input
                            type="search"
                            placeholder="Search Model, SKU, UPC"
                            name="searchString"
                            value={searchString}
                            ref={searchInputRef}
                            onChange={(e) => setSearchString(e.target.value)}
                          />
                        </div>
                        <ul>
                          {models.map((model) => (
                            <li
                              key={model.id}
                              onClick={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "modelSku",
                                      value: { id: model.id, name: model.name },
                                    },
                                  },
                                  index
                                )
                              }
                            >
                              {model.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="input-field">
                    <label>
                      IMEI <sup className="required">*</sup>
                    </label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="imei"
                      placeholder="3576963921923456"
                      value={line.imei}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </div>

                  <div className="input-field">
                    <label>
                      SIM <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="sim"
                      placeholder=""
                      value={line.sim}
                      onChange={(e) => handleChange(e, index)}
                      pattern="\d*"
                      onInput={(e) => {
                        const target = e.target as HTMLInputElement;
                        target.value = target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </div>

                  <div className="input-field">
                    <label>
                      Carrier <sup className="required">*</sup>
                    </label>
                    <button
                      type="button"
                      onFocus={() => handleOpenDropdownFocus(index, "Carrier")}
                      onClick={() => handleOpenDropdown(index, "Carrier")}
                    >
                      {line.carrier.name || "Select"}{" "}
                      <img src="/images/angle_down_grey.svg" alt="dropdown" />
                    </button>
                    {activeDropdown === index && activeName === "Carrier" && (
                      <div
                        className="carrier-search-main active"
                        ref={dropdownRef}
                      >
                        <div className="search-bar">
                          <input
                            type="search"
                            placeholder="Search Carrier"
                            name="searchString"
                            value={carrierSearchString}
                            onChange={(e) =>
                              setCarrierSearchString(e.target.value)
                            }
                            ref={carrierInputRef}
                          />
                        </div>
                        <ul>
                          {carriers.map((carrier) => (
                            <li
                              key={carrier.id}
                              onClick={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "carrier",
                                      value: {
                                        id: carrier.id,
                                        name: carrier.name,
                                      },
                                    },
                                  },
                                  index
                                )
                              }
                            >
                              {carrier.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="input-field">
                    <label>
                      Port? <sup className="required">*</sup>
                    </label>
                    <button
                      type="button"
                      onFocus={() => handleOpenDropdownFocus(index, "Port?")}
                      onClick={() => handleOpenDropdown(index, "Port?")}
                    >
                      {line.port.name || "Select"}
                      <img src="/images/angle_down_grey.svg" alt="dropdown" />
                    </button>
                    {activeDropdown === index && activeName === "Port?" && (
                      <div
                        className="carrier-search-main active"
                        ref={dropdownRef}
                      >
                        <div className="search-bar">
                          <input
                            type="search"
                            placeholder="Search Carrier"
                            name="searchString"
                            value={portSearchString}
                            onChange={(e) =>
                              setPortSearchString(e.target.value)
                            }
                            ref={portInputRef}
                          />
                        </div>
                        <ul>
                          {ports.map((port) => (
                            <li
                              key={port.id}
                              onClick={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "port",
                                      value: { id: port.id, name: port.name },
                                    },
                                  },
                                  index
                                )
                              }
                            >
                              {port.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="input-field">
                    <label>
                      Today <sup className="required">*</sup>
                    </label>
                    <div className="input-wrapper">
                      <span className="dollar-symbol">$</span>
                      <input
                        type="number"
                        autoComplete="off"
                        name="today"
                        placeholder=""
                        value={line.today}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </div>
                  </div>
                  <div className="input-field">
                    <label>
                      Monthly <sup className="required">*</sup>
                    </label>
                    <div className="input-wrapper">
                      <span className="dollar-symbol">$</span>
                      <input
                        type="text"
                        autoComplete="off"
                        name="monthly"
                        placeholder=""
                        value={line.monthly}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </div>
                  </div>

                  {/* Pin Input */}
                  <div className="input-field">
                    <label>Pin</label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="pins"
                      placeholder="123456"
                      value={line.pins}
                      onChange={(e) => handleChange(e, index)}
                      maxLength={6}
                    />
                  </div>

                  {/* Notes Input */}
                  <div className="input-field">
                    <label>Notes</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        autoComplete="off"
                        name="notes"
                        placeholder="Notes"
                        value={line.notes}
                        onChange={(e) => handleChange(e, index)}
                      />
                      <span
                        onClick={handleGrammar}
                        style={{
                          position: "absolute",
                          top: "50%",
                          cursor: "pointer",
                          right: "15px",
                          transform: "translateY(-50%)",
                        }}
                      >
                        {grammarLoading ? (
                          <span>
                            <Loading />{" "}
                          </span>
                        ) : (
                          <img
                            src={grammarIcon}
                            alt="grammar icon"
                            width={"25px"}
                          />
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="input-field">
                    <label>
                      VP Order Number <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="vpOrderNumber"
                      placeholder=""
                      value={line.vpOrderNumber}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </div>

                  {/* Checkbox Inputs */}
                  <div className="input-field check-field">
                    <div className="check-input">
                      <label>I.D Veriff</label>
                      <input
                        type="checkbox"
                        name="idVerify"
                        checked={line.idVerify}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </div>
                    <div className="check-input">
                      <label>AutoPay</label>
                      <input
                        type="checkbox"
                        name="autopay"
                        checked={line.autopay}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </div>
                  </div>
                </div>
                {lines.length === index + 1 && (
                  <div className="submit-btns">
                    <button type="button" onClick={addLine}>
                      ADD A LINE
                    </button>
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="save-btn"
                    >
                      {isButtonLoading ? <Loading /> : "SUBMIT"}
                    </button>
                  </div>
                )}
              </form>
            </div>
          ))}
          {deletePopup && (
            <div className="carrier-overlay">
              <div className="delete-main">
                <button className="delete-icon">
                  <img src="/images/delete_icon.svg" alt="" />
                </button>
                <h2>Please Confirm!</h2>
                <p>Are you sure, you want to delete</p>
                <div className="block-btn">
                  <button
                    className="cancle-btn"
                    onClick={() => setDeletePopup(false)}
                  >
                    Cancel
                  </button>
                  <button className="logout-btn" onClick={handleDeletePort}>
                    {isDeleteButtonLoading ? <Loading /> : "Delete"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default LineDetails;
