import React from "react";
import { generateInitials, textEllipsis } from "../../utils/common";

const UserInfo = ({
  cancelHandle,
  viewData,
}: {
  cancelHandle: () => void;
  viewData: any;
}) => {

  return (
    <>
      <div className="user-info-overlay">
        <div className="user-info-main">
          <button className="close-btn" onClick={cancelHandle}>
            <img src="images/times_icon.svg" alt="" />
          </button>
          <div className="head">
            <h3>User Info</h3>
          </div>
          <div className="info-details">
            <div className="profile">
              {viewData?.image ? (
                <>
                  <img src={viewData?.image} alt="" />
                </>
              ) : (
                generateInitials(viewData?.firstName, viewData?.lastName)
              )}
            </div>
            <div className="basic-info">
              <div className="input-group">
                <div className="input-field">
                  <label>First Name</label>
                  <input
                    type="text"
                    placeholder="Henry"
                    disabled
                    value={viewData?.firstName}
                  />
                </div>
                <div className="input-field">
                  <label>Last Name</label>
                  <input
                    type="text"
                    placeholder="Estward"
                    disabled
                    value={viewData?.lastName}
                  />
                </div>
                <div className="input-field">
                  <label>Position</label>
                  <div style={{ position: "relative" }}>
                    <input
                      type="text"
                      placeholder="Dealer / Owner"
                      disabled
                      value={textEllipsis(viewData?.position, 25)}
                    />
                    <span>{viewData?.positionShortName}</span>
                  </div>
                </div>
                <div className="input-field">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="abcde@gmail.com"
                    disabled
                    value={viewData?.email}
                  />
                </div>
                <div className="input-field">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    placeholder="11111111111"
                    disabled
                    value={viewData?.number}
                  />
                </div>
                <div className="input-field">
                  <label>Store Name</label>
                  <input
                    type="text"
                    placeholder="Name"
                    disabled
                    value={viewData?.store?.storeName}
                  />
                </div>
                <div className="input-field">
                  <label>Store Zip Code</label>
                  <input
                    type="text"
                    placeholder="111111"
                    disabled
                    value={viewData?.zipCode}
                  />
                </div>
                <div className="input-field">
                  <label>TSP ID</label>
                  <input
                    type="text"
                    placeholder="11111111111"
                    disabled
                    value={viewData?.tspId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInfo;
