import React, { useState, useEffect, useRef } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { dropDownData, getSalesEntryData } from "../config/api";
import moment from "moment";
import { formatDateNew } from "../utils/common";

const RenderHeader = ({
  column,
  onFilterChange,
  filters,
  fetchFilteredData,
  sortBy,
  sortOrder,
  onSortChange,
  selectedSalesType,
}: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredItems, setFilteredItems] = useState<any[]>(options);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const sortArrowRef = useRef<HTMLSpanElement>(null);
  // const [repNames, setRepNames] = useState<any[]>([]);

  const open = Boolean(anchorEl);
  const isDateTimeField = column.name === "dateTime";
  const isBooleanField =
    column.name === "idVerified" || column.name === "autoPay";
  // const isRepNameField = column.name === "repName";

  useEffect(() => {
    if (filters[column.name]) {
      setCheckedItems(filters[column.name]);
    }
  }, [filters, column.name]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !sortArrowRef.current?.contains(event.target as Node)
      ) {
        setAnchorEl(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const formatDate = (date: any) => {
    const d = new Date(date);
    const day = String(d.getUTCDate()).padStart(2, "0");
    const month = String(d.getUTCMonth() + 1).padStart(2, "0");
    const year = d.getUTCFullYear();
    return `${month}-${month}-${year}`;
  };

  const handleMenuOpen = async (event: React.MouseEvent<HTMLElement>) => {
    if (open) return;

    setAnchorEl(event.currentTarget);
    event.stopPropagation();

    if (!isDataFetched) {
      setLoading(true);
      try {
        let newOptions = [];
        if (isBooleanField) {
          newOptions = ["Yes", "No"];
        } else if (
          ["Plan", "Model/SKU", "Carrier", "Port"].includes(selectedSalesType)
        ) {
          const columnOptions = await getSalesEntryData(
            selectedSalesType,
            column.name
          );
          console.log(
            "Fetched Data:============================ ",
            columnOptions
          );

          if (!columnOptions || !Array.isArray(columnOptions)) {
            console.error(
              "Error: columnOptions is not an array or is undefined/null."
            );
            return;
          }

          newOptions = columnOptions.length
            ? columnOptions.map((row: any) => {
                if (column.name === "Carrier") {
                  return row.type;
                }
                if (column.name === "value") {
                  return row.value;
                }
                if (
                  column.name === "start_date" ||
                  column.name === "end_date"
                ) {
                  return moment(row.date).format("MM/DD/YYYY");
                }
                return row.type;
              })
            : [...new Set(columnOptions.map((row: any) => row[column.name]))];
          console.log("==========newOptions===========", newOptions);
        } else if (isDateTimeField) {
          const columnOptions = await dropDownData(column.name);
          newOptions = columnOptions.length
            ? columnOptions
            : [...new Set(columnOptions.map((row: any) => row[column.name]))];
          newOptions = newOptions.map((dateTime: string) => {
            const formattedDate = formatDateNew(dateTime);
            return `${formattedDate}`;
          });
        } else if (column.name === "storeName") {
          const columnOptions = await dropDownData("storeName");
          newOptions = [
            ...new Set(columnOptions.map((row: any) => row.storeName)),
          ];
        } else {
          const columnOptions = await dropDownData(column.name);
          newOptions = columnOptions.length
            ? columnOptions
            : [...new Set(columnOptions.map((row: any) => row[column.name]))];
        }

        if (column.name === "repName") {
          newOptions = newOptions.map((item: any) => ({
            id: item.id,
            name: item.name,
          }));
        }

        setOptions(newOptions);
        setFilteredItems(newOptions);
        setIsDataFetched(true);
      } catch (error) {
        console.error("Error fetching column data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSearchTerm("");
    setFilteredItems(options);
  };

  const toggleChecked = (item: string) => {
    setCheckedItems((prev) => {
      const newCheckedItems = prev.includes(item)
        ? prev.filter((i) => i !== item)
        : [...prev, item];
      return newCheckedItems;
    });
  };

  const applyFilter = () => {
    if (sortBy === column.name) {
      const newSortOrder = sortOrder === "descending";
      onSortChange(column.name, newSortOrder);
    } else {
      onSortChange(column.name, "descending");
    }

    const mappedCheckedItems =
      column.name === "autoPay" || column.name === "idVerified"
        ? checkedItems.map((item) => (item === "Yes" ? true : false))
        : checkedItems;

    const updatedFilters = {
      ...filters,
      [column.name]: mappedCheckedItems,
    };

    onFilterChange(column.name, mappedCheckedItems);
    // fetchFilteredData(updatedFilters);
    handleMenuClose();
  };

  const handleSort = (order: string, e: React.MouseEvent) => {
    e.stopPropagation();
    if (sortBy === column.name) {
      const newSortOrder = order === "ascending" ? "descending" : "ascending";
      onSortChange(column.name, newSortOrder);
    } else {
      onSortChange(column.name, "ascending");
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (options.length > 0) {
      const filtered = options.filter((item) => {
        const itemString = typeof item === "string" ? item : String(item);
        return itemString.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredItems(filtered);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !sortArrowRef.current?.contains(event.target as Node)
      ) {
        setAnchorEl(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectAll = () => {
    const allSelected = checkedItems.length === filteredItems.length;

    if (allSelected) {
      setCheckedItems([]);
    } else {
      if (column.name === "repName") {
        const allIds = filteredItems.map((item) => item.id);
        setCheckedItems(allIds);
      } else {
        setCheckedItems(filteredItems);
      }
    }
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      className="custom-flex"
      ref={dropdownRef}
      onClick={(e) => {
        e.stopPropagation();
        handleMenuOpen(e);
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>{column.header}</span>
        <span
          style={{ cursor: "pointer", transform: "scale(1.4)" }}
          ref={sortArrowRef}
        >
          <span
            onClick={(e) => {
              e.stopPropagation();
              handleSort("ascending", e);
            }}
          >
            ↑
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              handleSort("descending", e);
            }}
          >
            ↓
          </span>
        </span>
      </div>

      <Menu
        ref={menuRef}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          style: {
            width: 300,
            height: "auto",
            maxHeight: 350,
            position: "static",
            marginBottom: "40px",
            overflowY: "auto",
          },
        }}
        disableScrollLock
      >
        <MenuItem style={{ display: "flex", justifyContent: "center" }}>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search..."
            autoComplete="off"
            onKeyDown={(e) => e.stopPropagation()}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
        </MenuItem>

        {loading ? (
          <MenuItem disabled onClick={(e) => e.stopPropagation()}>
            <CircularProgress size={24} />
            <span style={{ marginLeft: 10 }}>Loading...</span>
          </MenuItem>
        ) : filteredItems.length > 0 ? (
          [
            <MenuItem onClick={(e) => e.stopPropagation()} key="select-all">
              <Checkbox
                checked={checkedItems.length === filteredItems.length}
                onChange={handleSelectAll}
              />
              Select All
            </MenuItem>,
            ...filteredItems.map((option: any, index: number) => {
              const key = option.id
                ? String(option.id)
                : `${String(option)}-${index}`;

              return (
                <MenuItem key={key}>
                  <Checkbox
                    checked={checkedItems.includes(option.id || option)}
                    onChange={() => toggleChecked(option.id || option)}
                  />
                  {option.name || option}
                </MenuItem>
              );
            }),
          ]
        ) : (
          <MenuItem disabled>No results found</MenuItem>
        )}

        {filteredItems.length > 0 && !loading && (
          <MenuItem
            onClick={(e) => e.stopPropagation()}
            style={{
              position: "absolute",
              bottom: "10px",
              left: 0,
              width: "100%",
            }}
          >
            <Button
              fullWidth
              variant="contained"
              size="small"
              onClick={applyFilter}
            >
              Apply
            </Button>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
export default RenderHeader;
