import React, { useEffect, useRef, useState } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import Loading from "../common/Loading";
import VerifyOtp from "./VerifyOtp";
import { customerVerifyOtp, getStores, signUp } from "../../config/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useAuth } from "@realm/react";

interface Store {
  id: string;
  storeName: string;
}

const SignUp = ({ handleForgot }: { handleForgot: (a: string) => void }) => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    storeId: "",
    storeZipCode: "",
    tspId: "",
    password: "",
  });

  const [position, setPosition] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const navigate = useNavigate();
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [selectedStore, setSelectedStore] = useState("");
  const { logInWithJWT, result } = useAuth();
  const [stores, setStores] = useState<Store[]>([]);
  const [dealer, setDealer] = useState("Select");
  const [positionShortName, setPositionShortName] = useState("");
  const [options, setOptions] = useState();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const roles = [
    { title: "Dealer/Owner", abbreviation: "DLR" },
    { title: "District Manager", abbreviation: "DM" },
    { title: "Retail Store Manager", abbreviation: "RSM" },
    { title: "Retail Store Assistant Manager", abbreviation: "RAM" },
    { title: "Retail Store Associate", abbreviation: "RSA" },
    { title: "Operations", abbreviation: "OPR" },
  ];

  const handleSelect = (value: string, shortName: string) => {
    setDealer(value);
    setPositionShortName(shortName);
    setPosition(false);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name == "phoneNumber") {
      if (value.length > 10) return;
    }
    if (name === "tspId") {
      setData({
        ...data,
        [name]: value ? parseInt(value, 10) : "", // Set to empty string if the value is empty
      });
    }

    setData({
      ...data,
      [name]: value,
    });
  };

  const backHandler = () => {
    setVerifyOtp(false);
  };

  useEffect(() => {
    const getAllStores = async () => {
      try {
        const resp: any = await getStores("");
        console.log(resp);
        setStores(resp?.data || []);
      } catch (error) {
        console.error("Error in fetching stores", error);
      }
    };
    getAllStores();
  }, []);

  const verifySubmit = async (otp: string) => {
    try {
      setIsLoading(true);
      const parsedTspId = data.tspId ? parseInt(data.tspId, 10) : null;
      const res: any = await customerVerifyOtp({
        ...data,
        otp: otp,
        dealer: dealer,
        positionShortName: positionShortName,
        role: "sales_member",
        tspId: parsedTspId,
      });
      if (res?.status) {
        logInWithJWT(res?.token);
        localStorage.setItem("token", res?.token);
        localStorage.setItem("role", "sales_member");
        navigate("/calculator");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleSubmit = async () => {
    for (let key in data) {
      if (data[key as keyof typeof data] === "") {
        return toast.error("All fields required");
      }
    }
    if (dealer == "Select") {
      return toast.error("All fields required");
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const isValid = emailRegex.test(data.email);

    if (!isValid) {
      return toast.error("Please enter valid email!");
    }

    try {
      setIsLoading(true);
      let res: any = await signUp({
        phoneNumber: data.phoneNumber,
        email: data.email,
      });
      if (res?.status) {
        setVerifyOtp(true);
        setIsLoading(false);
      }
    } catch (error: any) {
      toast.error(error?.message);
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const handleOpenDropdown = () => {
    setActiveDropdown(true);
  };

  const handleSelectStore = (storeId: string, storeName: string) => {
    setData({
      ...data,
      storeId,
    });
    setSelectedStore(storeName);
    setActiveDropdown(false);
  };

  return (
    <>
      {verifyOtp ? (
        <VerifyOtp
          resendOtpLoading={isLoading}
          isLoading={isLoading}
          email={data.email}
          backHandler={backHandler}
          handleForgot={handleForgot}
          verifySubmit={verifySubmit}
        />
      ) : (
        <>
          <div className="logo">
            <a href="#">
              <img src="images/Logo.png" alt="Logo" />
            </a>
          </div>
          <div className="login-input-details">
            <h2>Sign Up</h2>
            <p>Enter your details to create to your account</p>
            <div className="input-group">
              <div className="input-field">
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  onChange={handleChange}
                  value={data.firstName}
                />
                <span className="icon">
                  <img src="images/new_user_icon.svg" alt="" />
                </span>
              </div>
              <div className="input-field">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  value={data.lastName}
                />
                <span className="icon">
                  <img src="images/new_user_icon.svg" alt="" />
                </span>
              </div>
            </div>
            <div className="input-group">
              <button onClick={() => setPosition(!position)}>
                {dealer} <img src="images/angle_down_grey.svg" />
              </button>
              <span className="icon">
                <img src="images/new_user_icon.svg" alt="" />
              </span>
              {position && (
                <div className="dropdown-list-main">
                  <ul>
                    {roles.map((value) => (
                      <li
                        key={value.abbreviation}
                        onClick={() =>
                          handleSelect(value.title, value.abbreviation)
                        }
                      >
                        <span>{value.title}</span>
                        <span>{value.abbreviation}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="input-group">
              <div className="input-field">
                <input
                  type="mail"
                  placeholder="abc@gmail.com"
                  name="email"
                  onChange={handleChange}
                  value={data.email}
                />
                <span className="icon">
                  <img src="images/mail_icon.svg" alt="" />
                </span>
              </div>
              <div className="input-field">
                <input
                  type="number"
                  placeholder="Mobile Number"
                  name="phoneNumber"
                  onChange={handleChange}
                  value={data.phoneNumber}
                />
                <span className="icon">
                  <img src="images/password_icon.svg" alt="" />
                </span>
              </div>
            </div>
            <div className="input-group">
              {/* <input
                type="text"
                placeholder="Store Name"
                name="storeName"
                onChange={handleChange}
                value={data.storeName}
              /> */}
              <button type="button" onClick={() => handleOpenDropdown()}>
                {selectedStore ? selectedStore : "Select"}
                <img src="images/angle_down_grey.svg" alt="dropdown" />
              </button>
              {activeDropdown && (
                <div
                  className="carrier-search-main-data active"
                  ref={dropdownRef}
                >
                  <ul>
                    {stores.map((store, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleSelectStore(store.id, store?.storeName)
                        }
                      >
                        {store?.storeName}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <span className="icon">
                <img src="images/password_icon.svg" alt="" />
              </span>
            </div>
            <div className="input-group">
              <div className="input-field">
                <input
                  type="text"
                  placeholder="Store Zip Code"
                  name="storeZipCode"
                  onChange={handleChange}
                  value={data.storeZipCode}
                />
                <span className="icon">
                  <img src="images/new_user_icon.svg" alt="" />
                </span>
              </div>
              <div className="input-field">
                <input
                  type="text"
                  placeholder="TSP ID"
                  name="tspId"
                  onChange={handleChange}
                  value={data.tspId}
                />
                <span className="icon">
                  <img src="images/new_user_icon.svg" alt="" />
                </span>
              </div>
            </div>
            <div className="input-group mb">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                name="password"
                onChange={handleChange}
                value={data.password}
              />
              <span className="icon">
                <img
                  className="mobile-icon"
                  src="images/New_password_icon.svg"
                  alt=""
                />
              </span>
              <span
                className="eye-icon"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {" "}
                {passwordVisible ? <LuEye size={20} /> : <LuEyeOff size={20} />}
              </span>
            </div>
            <div className="login-btn">
              <button onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? <Loading /> : "Submit"}
              </button>
            </div>
            <div className="have-account">
              <p>
                Already have an account?{" "}
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => handleForgot("login")}
                >
                  Log In
                </a>
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SignUp;
