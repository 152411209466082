import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import Main from "./components/auth/Main";
import Dashboard from "./pages/dashboard/Dashboard";
import Setting from "./pages/setting/Setting";

import "./assets/css/style.css";
import "./assets/css/responsive.css";

import WebDashboard from "./website/pages/WebDashboard";
import WebProfileScreen from "./website/pages/WebProfileScreen";

import { AppProvider } from "@realm/react";

import { AuthenticatedApp } from "./AuthenticatedApp";
import { AdminAuthenticatedApp } from "./AdminAuthenticatedApp";
import config from "./atlas-app-services/config.json";
import WebsiteUsers from "./admin/WebsiteUsers";
import IncidentsList from "./admin/IncidentsList";
import IncidentMain from "./website/pages/IncidentMain";
import WebSettings from "./website/pages/WebSettings";
import Calculator from "./website/pages/Calculator";
import AdminIncidentView from "./admin/AdminIncidentView";
import init from "../src/pkg/demo_wasm";
import { useEffect } from "react";
import SalesEntry from "./admin/SalesEntry";
import AddSalesEntryForm from "./components/common/AddPlanForm";
import AddModelForm from "./components/common/AddModelForm";
import AddPortForm from "./components/common/AddPortForm";
import SalesEntryWeb from "./website/pages/SaleEntryWeb";
import SalesWeb from "./website/pages/SalesWeb";
import NewActivation from "./website/pages/NewActivation";
import AddALine from "./website/pages/AddALine";
import LineDetails from "./website/pages/LineDetails";

function App() {
  useEffect(() => {
    const integrateWasm = async () => {
      try {
        await init();
      } catch (error) {
        console.log("error", error);
      }
    };

    integrateWasm();
  }, []);

  return (
    <>
      <AppProvider id={process.env.REACT_APP_ATLAS_APP_ID || ""}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route
            path="/dashboard"
            element={
              <AdminAuthenticatedApp>
                <Dashboard />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/setting"
            element={
              <AdminAuthenticatedApp>
                <Setting />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/calculator"
            element={
              <AuthenticatedApp>
                <Calculator />{" "}
              </AuthenticatedApp>
            }
          />
          <Route
            path="/website-users"
            element={
              <AdminAuthenticatedApp>
                <WebsiteUsers />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/incidents"
            element={
              <AdminAuthenticatedApp>
                <IncidentsList />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/sales"
            element={
              <AdminAuthenticatedApp>
                <SalesEntry />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/sales/add-plan"
            element={
              <AdminAuthenticatedApp>
                <AddSalesEntryForm />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/sales/add-model"
            element={
              <AdminAuthenticatedApp>
                <AddModelForm />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/sales/add-port"
            element={
              <AdminAuthenticatedApp>
                <AddPortForm />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/sales/edit-plan"
            element={
              <AdminAuthenticatedApp>
                <AddSalesEntryForm />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/sales/edit-model"
            element={
              <AdminAuthenticatedApp>
                <AddModelForm />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/sales/edit-port"
            element={
              <AdminAuthenticatedApp>
                <AddPortForm />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/sales/view-plan"
            element={
              <AdminAuthenticatedApp>
                <AddSalesEntryForm />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/sales/view-model"
            element={
              <AdminAuthenticatedApp>
                <AddModelForm />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/sales/view-port"
            element={
              <AdminAuthenticatedApp>
                <AddPortForm />{" "}
              </AdminAuthenticatedApp>
            }
          />

          <Route
            path="/incident-view/:id"
            element={
              <AdminAuthenticatedApp>
                <AdminIncidentView />{" "}
              </AdminAuthenticatedApp>
            }
          />
          <Route
            path="/sales/line-view/:id"
            element={
              <AuthenticatedApp>
                <LineDetails />{" "}
              </AuthenticatedApp>
            }
          />
          <Route
            path="/website-incidents"
            element={
              <AuthenticatedApp>
                <IncidentMain />{" "}
              </AuthenticatedApp>
            }
          />
          <Route
            path="/website-sales"
            element={
              <AuthenticatedApp>
                <SalesWeb />{" "}
              </AuthenticatedApp>
            }
          />
          <Route
            path="/new-activation"
            element={
              <AuthenticatedApp>
                <NewActivation />{" "}
              </AuthenticatedApp>
            }
          />
          <Route
            path="/add-line"
            element={
              <AuthenticatedApp>
                <AddALine />{" "}
              </AuthenticatedApp>
            }
          />
          <Route
            path="/sale-entry"
            element={
              <AuthenticatedApp>
                <SalesEntryWeb />{" "}
              </AuthenticatedApp>
            }
          />
          <Route
            path="/setting-profile"
            element={
              <AuthenticatedApp>
                <WebSettings />{" "}
              </AuthenticatedApp>
            }
          />
          <Route path="*" element={<h1>No Page Found</h1>} />
        </Routes>
        <Toaster />
      </AppProvider>
    </>
  );
}

export default App;
