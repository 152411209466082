import Pagination from "../../components/common/Pagination";
import Header from "../../components/common/Header";
import { useContext, useEffect, useRef, useState } from "react";
import { addExcelSales, getSaleFile, getWebSales } from "../../config/api";
import moment from "moment";
import PageLoading from "../../components/common/PageLoading";
// import DataGrid from "../../admin/DataGrid";
import { BsThreeDots } from "react-icons/bs";
import Illustration from "../../components/common/Illustration";
import CustomDataTable from "../../admin/CustomDataTable";
import AppContext from "../../context/AppProvider";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import Loading from "../../components/common/Loading";
import { getStoreId } from "../../utils/common";

const SalesWeb = () => {
  const { profileData } = useContext(AppContext);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [errorPopup, setErrorPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [csvLoading, setCsvLoading] = useState(false);
  const columns = [
    {
      defaultFlex: 1,
      name: "tspId",
      header: "TSP ID",
      render: ({ data }: any) => <>{data.tspId ? data.tspId : "-"}</>,
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 2,
      name: "store.storeName",
      header: "Store Name",
      minWidth: 150,
      visible: true,
      render: ({ data }: any) => {
        const storeName = data?.store?.storeName || "-";
        return <>{storeName}</>;
      },
      getValue: ({ data }: any) => {
        return data?.store?.storeName || "-";
      },
    },

    {
      defaultFlex: 3,
      name: "repName",
      render: ({ data }: any) => <>{data.repName ? data.repName : "-"}</>,
      header: "Rep Name",
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 4,
      name: "repId",
      render: ({ data }: any) => <>{data.repId ? data.repId : "-"}</>,
      header: "Rep ID",
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 5,
      name: "dateTime",
      header: "Display Date",
      render: ({ data }: any) => (
        <>{moment(data.dateTime).format("MM/DD/YYYY") || "-"}</>
      ),
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 6,
      name: "number",
      render: ({ data }: any) => <>{data.number ? data.number : "-"}</>,
      header: "Phone#",
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 7,
      name: "modelSku",
      render: ({ data }: any) => <>{data.modelSku ? data.modelSku : "-"}</>,
      header: "Model/SKU",
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 8,
      name: "imei",
      render: ({ data }: any) => <>{data.imei ? data.imei : "-"}</>,
      header: "IMEI",
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 9,
      name: "sim",
      render: ({ data }: any) => <>{data.sim ? data.sim : "-"}</>,
      header: "Sim",
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 10,
      name: "idVerified",
      render: ({ data }: any) =>
        data.idVerified === true
          ? "Yes"
          : data.idVerified === false
          ? "No"
          : "-",
      header: "I.D Veriff",
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 11,
      name: "autoPay",
      render: ({ data }: any) =>
        data.autoPay === true ? "Yes" : data.autoPay === false ? "No" : "-",
      header: "AutoPay",
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 12,
      name: "vpOrderNumber",
      render: ({ data }: any) => (
        <>{data.vpOrderNumber ? data.vpOrderNumber : "-"}</>
      ),
      header: "VP Order Number",
      minWidth: 200,
      visible: true,
    },
    {
      defaultFlex: 13,
      name: "carrier",
      render: ({ data }: any) => <>{data.carrier ? data.carrier : "-"}</>,
      header: "Carrier",
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 14,
      name: "port",
      render: ({ data }: any) => <>{data.port ? data.port : "-"}</>,
      header: "Port",
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 15,
      name: "line",
      render: ({ data }: any) => <>{data.line ? data.line : "-"}</>,
      header: "Line#",
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 16,
      name: "plan",
      render: ({ data }: any) => <>${data.planValue ? data.planValue : "-"}</>,
      header: "Plan",
      minWidth: 150,
      visible: true,
    },
    {
      defaultFlex: 17,
      name: "firstPayment",
      header: "First Payment",
      render: ({ data }: any) => (
        <>${data.firstPayment ? data.firstPayment : "-"}</>
      ),
      minWidth: 150,
      visible: true,
    },
  ].map((col) => ({ ...col, visible: true }));

  const columnsWithAction = [...columns];

  if (
    profileData?.salesPermissions?.length === 1 &&
    profileData?.salesPermissions[0] === "View"
  ) {
    columnsWithAction.push({
      defaultFlex: 18,
      name: "action",
      header: "Action",
      minWidth: 100,
      render: ({ data: rowData }: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <button
              className="action_btn"
              style={{ backgroundColor: "transparent", cursor: "pointer" }}
              onClick={() => handleEyeButtonClick(rowData)}
            >
              <BsThreeDots />
            </button> */}

            <div
              className={`action-main action-main-new`}
              style={{
                zIndex: 9999,
                position: "absolute",
                display: rowData.showMenu ? "block" : "none",
                left: "-120px",
              }}
            >
              <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                <li style={{ cursor: "pointer" }}>
                  <Link
                    to={`/sales/line-view/${rowData?.saleId}`}
                    className="edit-btn"
                  >
                    <span>
                      <img src="images/action_eye.svg" alt="View" />
                    </span>
                    Edit Info
                  </Link>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <a className="block-btn">
                    <span>
                      <img src="images/permission_icon.svg" alt="Delete" />
                    </span>
                    Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
        );
      },
      visible: true,
    });
  } else {
    columnsWithAction.push({
      defaultFlex: 18,
      name: "action",
      header: "Action",
      minWidth: 100,
      render: ({ data: rowData }: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className={`action-main action-main-new`}
              style={{
                zIndex: 9999,
                position: "absolute",
                display: rowData.showMenu ? "block" : "none",
                left: "-120px",
              }}
            >
              <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                <li style={{ cursor: "pointer" }}>
                  <Link
                    to={`/sales/line-view/${rowData?.saleId}`}
                    className="edit-btn"
                  >
                    <span>
                      <img src="images/action_eye.svg" alt="View" />
                    </span>
                    Edit Info
                  </Link>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <a className="block-btn">
                    <span>
                      <img src="images/permission_icon.svg" alt="Delete" />
                    </span>
                    Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
        );
      },
      visible: true,
    });
  }

  const firstPaymentIndex = columnsWithAction.findIndex(
    (col) => col.name === "firstPayment"
  );

  if (firstPaymentIndex !== -1) {
    const actionColumn = columnsWithAction.pop();
    if (actionColumn) {
      columnsWithAction.splice(firstPaymentIndex + 1, 0, actionColumn);
    }
  }

  const [salesData, setSalesData] = useState<any>({
    sales: [],
    total: 0,
    totalPages: 0,
  });
  const [visibleColumns, setVisibleColumns] = useState(columnsWithAction);
  const [isLoading, setIsLoading] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [sortBy, setSortBy] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [sortOrder, setSortOrder] = useState<string>("descending");
  const [params, setParams] = useState({
    page: 1,
    perPage: 10,
  });

  const handleColumnChange = (column: any, isVisible: any) => {
    setVisibleColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.name === column.name ? { ...col, visible: isVisible } : col
      )
    );
  };

  const pageHandler = (e: any) => {
    const page = e.selected + 1;
    setParams({ ...params, page });
  };

  const handlePageSelect = (value: number) => {
    setParams({ ...params, perPage: value });
  };

  const [actionToggle, setActionToggle] = useState(false);

  const handleEyeButtonClick = (data: any) => {
    setActionToggle(true);
  };

  const handleSortChange = (newSortBy: string, newSortOrder: string) => {
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
  };

  const fetchSaleListing = async () => {
    setIsLoading(true);
    try {
      const response: any = await getWebSales(
        params.page,
        params.perPage,
        searchString,
        filters,
        sortBy,
        sortOrder
      );

      if (response?.data?.sales?.length > 0) {
        setSalesData(response.data);
      } else {
        setSalesData({ sales: [], total: 0, totalPages: 0 });
      }
    } catch (err) {
      console.error("Error fetching sales data:", err);
      setSalesData({ sales: [], total: 0, totalPages: 0 });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const debounceTimeout = setTimeout(() => {
      fetchSaleListing();
    }, 500);

    return () => {
      clearTimeout(debounceTimeout);
      isMounted = false;
    };
  }, [searchString, params, filters, sortBy, sortOrder]);

  const handleFilterChange = (
    columnName: string,
    selectedOptions: string[]
  ) => {
    const updatedFilters = { ...filters, [columnName]: selectedOptions };

    Object.keys(updatedFilters).forEach((key) => {
      if (updatedFilters[key].length === 0) {
        delete updatedFilters[key];
      }
    });

    setFilters(updatedFilters);
  };

  const toggleColumnVisibility = (columnName: string) => {
    setVisibleColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.name === columnName ? { ...col, visible: !col.visible } : col
      )
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        document.getElementById("columns-dropdown")?.classList.remove("show");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
  };

  const trimToAlphabets = (name: string): string => {
    return name.replace(/[^a-zA-Z]/g, "").slice(0, 10);
  };

  const handleExcelData = async () => {
    if (!selectedFile) {
      toast.success("Select a file to add plan.");
      return;
    }
    setFileLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const response: any = await addExcelSales(formData);
      setSelectedFile(null);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      toast.success(response?.message);
      setFileLoading(false);
      fetchSaleListing();
    } catch (error: any) {
      console.error("Error uploading file:", error);
      setErrorMessage(error?.errors);
      setErrorPopup(true);
      setMessage(error?.message);
      setFileLoading(false);
    }
  };

  const exportAsCSV = async () => {
    try {
      setCsvLoading(true);
      const storeId = getStoreId();
      let response: any = await getSaleFile(storeId);
      if (response?.status) {
        const anchor = document.createElement("a");
        anchor.href = response?.exportedFile;
        anchor.download = response?.exportedFile;
        anchor.click();
        anchor.remove();
        setCsvLoading(false);

        toast.success(response?.message);
      }
    } catch (error: any) {
      setCsvLoading(false);
      toast.error(error?.message);
    }
  };

  return (
    <>
      <Header
        title={"Sale"}
        backButton={null}
        backHandle={null}
        admin={false}
      />
      <div className="loaction-list-main web-sales-main">
        <div className="loaction-list-head">
          <div className="search-bar">
            {/* <input
              type="search"
              placeholder="Search by name"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            <img src="images/search_icon.svg" alt="" /> */}
          </div>
          <div className="upload-sale-button">
            <div className="file-input">
              <input
                type="file"
                className="add-excel-file"
                accept=".xlsx, .xls"
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              {selectedFile ? (
                <div className="selected-file-container">
                  <span>{trimToAlphabets(selectedFile.name)}</span>
                </div>
              ) : (
                "Upload Sale"
              )}
              {selectedFile && (
                <div className="cross-image">
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedFile(null);
                      if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                      }
                    }}
                    src="images/times_icon.svg"
                    alt=""
                  />
                </div>
              )}
            </div>

            {selectedFile && (
              <button className="upload-file_button" onClick={handleExcelData}>
                {fileLoading ? (
                  <div className="upload_loader"></div>
                ) : (
                  "Upload Sale"
                )}
              </button>
            )}
            <div>
              <button
                className="export-btn"
                onClick={exportAsCSV}
                disabled={csvLoading}
              >
                {csvLoading ? <Loading /> : "Export"}
              </button>
            </div>
          </div>
          <button
            className="toggle-columns-btn"
            onClick={() =>
              document
                .getElementById("columns-dropdown")
                ?.classList.toggle("show")
            }
          >
            Show/Hide Fields
          </button>
          <div
            id="columns-dropdown"
            className="columns-dropdown"
            ref={dropdownRef}
          >
            {columns.map((col, index) => (
              <div key={index} className="checkbox-wrapper">
                <label>
                  <input
                    type="checkbox"
                    checked={visibleColumns.some(
                      (visibleCol) =>
                        visibleCol.name === col.name && visibleCol.visible
                    )}
                    onChange={() => toggleColumnVisibility(col.name)}
                  />

                  {col.header}
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="table-data-main sales-entry-table-head web-sales-entry">
          <div className="table-data">
            {/* <DataGrid
              dataSource={salesData?.sales}
              visibleColumns={visibleColumns}
            /> */}
            {isLoading ? (
              <PageLoading />
            ) : salesData?.sales?.total == 0 ? (
              <Illustration
                imgLink={"/images/noIncident.svg"}
                title="No Incidents Yet!"
              />
            ) : (
              <CustomDataTable
                dataSource={salesData?.sales}
                visibleColumns={visibleColumns.filter((col) => col.visible)}
                filters={filters}
                setFilters={setFilters}
                onSortChange={handleSortChange}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            )}
          </div>
        </div>
      </div>

      {salesData?.sales?.length !== 0 && (
        <Pagination
          paramsData={params}
          total={salesData?.total}
          pageCount={salesData?.totalPages}
          handlePageChange={pageHandler}
          handlePageSelect={handlePageSelect}
          increasePageSize={true}
        />
      )}
      {errorPopup && (
        <div className="carrier-overlay">
          <div className="delete-main error-main">
            <button className="delete-icon">
              <img src="/images/error.svg" alt="" />
            </button>
            <h2>Error!</h2>
            <p>
              {errorMessage ? (
                <ul>
                  {errorMessage?.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              ) : (
                message
              )}
            </p>
            <div className="block-btn">
              <button
                className="logout-btn"
                onClick={() => setErrorPopup(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SalesWeb;
