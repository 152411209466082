import React, { useEffect, useRef, useState } from "react";
import Header from "../components/common/Header";
import Permission from "../components/popUps/Permission";
import UserInfo from "../components/popUps/UserInfo";
import Confirmation from "../components/popUps/Confirmation";
import { getSheets, getWebsiteUser } from "../config/api";
import PageLoading from "../components/common/PageLoading";
import Pagination from "../components/common/Pagination";
import { generateInitials } from "../utils/common";
import Illustration from "../components/common/Illustration";
import AddUser from "../components/common/AddUser";
import { MdOutlineEdit } from "react-icons/md";
import SheetSelect from "./components/SheetSelect";
import IncidentExportSelect from "./components/IncidentExportSelect";

const columns = [
  { key: "name", label: "Name", isVisible: true },
  { key: "email", label: "Email", isVisible: true },
  { key: "phoneNumber", label: "Phone Number", isVisible: true },
  { key: "position", label: "Position", isVisible: true },
  { key: "storeName", label: "Store Name", isVisible: true },
  { key: "zipCode", label: "Zip Code", isVisible: true },
  { key: "tspId", label: "TspId", isVisible: true },
  { key: "action", label: "Action", isVisible: true },
];

const WebsiteUsers = () => {
  const [permission, setPermission] = useState(false);
  const [viewUser, setViewUser] = useState(false);
  const [deleteMember, setDeleteMember] = useState(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [params, setParams] = useState({
    page: 1,
    perPage: 10,
    search: "",
  });
  const [actionDrop, setActionDrop] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [data, setData] = useState<any>(null);
  const [viewData, setViewData] = useState<any>(null);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [sheetNumber, setSheetNumber] = useState<any>(null);
  const [columList, setColumList] = useState<any>(columns);
  const [visibleColumns, setVisibleColumns] = useState<any>(
    columList.reduce(
      (acc: any, col: any) => ({ ...acc, [col.key]: col.isVisible }),
      {}
    )
  );

  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      let res: any = await getWebsiteUser(
        params.page,
        params.perPage,
        params.search
      );
      if (res?.status) {
        setData(res?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "errorr");
    }
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      getData();
    }, 1000);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [params]);

  useEffect(() => {
    (async () => {
      try {
        const res: any = await getSheets();
        if (res?.status) {
          setSheetNumber(res?.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    })();
  }, []);

  const handleAction = (value: any) => {
    setViewData(value);
    setActionDrop(!actionDrop);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      const id = event.target.id;
      if (!id) {
        setActionDrop(false);
      }
    }
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handlePermission = () => {
    setAddUser(true);
  };
  const handleView = () => {
    setViewUser(true);
  };

  const cancelHandle = () => {
    setPermission(false);
    setViewUser(false);
    setViewData(null);
  };

  const deleteCancel = () => {
    setDeleteMember(false);
  };

  const deleteSubmitHandle = () => {
    return;
  };

  //* pagination page handler
  const pageHandler = (e: any) => {
    const page = e.selected + 1;
    setParams({ ...params, page });
  };
  const toggleColumnVisibility = (key: any) => {
    setVisibleColumns((prevState: any) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const toggleColumnSelector = () => {
    setShowColumnSelector(!showColumnSelector);
  };

  const closeHandle = () => {
    setAddUser(false);
    getData();
    setViewData(null);
  };

  useEffect(() => {
    // Disable scrolling when any popup is open
    if (addUser || viewUser || deleteMember) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [addUser, viewUser, deleteMember]);

  return (
    <>
      <Header
        title={"Website user"}
        backButton={null}
        backHandle={null}
        admin={true}
      />
      {addUser && (
        <AddUser
          closeHandle={closeHandle}
          viewData={viewData}
          setAddUser={setAddUser}
          setViewData={setViewData}
        />
      )}

      {viewUser && <UserInfo cancelHandle={cancelHandle} viewData={viewData} />}
      {deleteMember && (
        <Confirmation
          title={"Are you sure, you want to delete?"}
          isLoading={false}
          cancelHandle={deleteCancel}
          submitHandle={deleteSubmitHandle}
          btnTitle={"Delete"}
        />
      )}

      <div className="loaction-list-main">
        <div className="loaction-list-head">
          <div className="search-bar">
            <input
              type="search"
              placeholder="Search by name"
              value={params.search}
              onChange={(e: any) =>
                setParams({ ...params, search: e.target.value })
              }
            />
            <img src="images/search_icon.svg" alt="" />
          </div>
          <div className="web-add-user">
            {data?.total > 0 && (
              <div className="main-column">
                <button className="man" onClick={toggleColumnSelector}>
                  {showColumnSelector ? "Hide" : "Show"} Columns
                  {showColumnSelector && (
                    <div className="column-selector">
                      {columList.map((column: any) => (
                        <div key={column.label}>
                          <input
                            type="checkbox"
                            checked={visibleColumns[column.key]}
                            onChange={() => toggleColumnVisibility(column.key)}
                          />
                          <label>{column.label}</label>
                        </div>
                      ))}
                    </div>
                  )}
                </button>
              </div>
            )}
            <button className="add-user" onClick={() => setAddUser(true)}>
              + Add User
            </button>
          </div>
        </div>

        {loading ? (
          <PageLoading />
        ) : data?.total == 0 ? (
          <Illustration
            imgLink={"/images/nowebsiteuser.svg"}
            title="No Website Users Found!"
          />
        ) : (
          <div className="table-data-main" style={{ padding: "0" }}>
            <div
              className="table-data"
              style={{ width: "1540px", height: "64vh", overflowY: "auto" }}
            >
              <table border={0} cellPadding={0} style={{ width: "100%" }}>
                <thead
                  style={{
                    backgroundColor: "#fdfdfd",
                    borderBottom: "1px solid #ededed",
                    borderTop: "1px solid #ededed",
                  }}
                >
                  <tr>
                    {visibleColumns.name && <th className="heading">Name</th>}
                    {visibleColumns.email && <th className="heading">Email</th>}
                    {visibleColumns.phoneNumber && (
                      <th className="heading">Phone Number</th>
                    )}
                    {visibleColumns.position && (
                      <th className="heading">Position</th>
                    )}
                    {visibleColumns.storeName && (
                      <th className="heading">Store Name</th>
                    )}
                    {visibleColumns.zipCode && (
                      <th className="heading">Zip Code</th>
                    )}
                    {visibleColumns.tspId && <th className="heading">TspId</th>}
                    {<th className="heading">Sheet No.</th>}
                    {<th className="heading">Incident Export</th>}
                    {visibleColumns.action && (
                      <th
                        className="heading"
                        style={{ width: "7%", textAlign: "right" }}
                      >
                        Action
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data?.websiteUsers?.map((value: any) => {
                    return (
                      <>
                        <tr style={{ borderBottom: "1px solid #ededed" }}>
                          {visibleColumns.name && (
                            <td style={{ padding: "0px 25px" }}>
                              <table>
                                <tr>
                                  <td
                                    className="product-img"
                                    style={{ border: "none" }}
                                  >
                                    <a>
                                      {" "}
                                      {value?.image ? (
                                        <img src={value?.image} alt=" image" />
                                      ) : (
                                        generateInitials(
                                          value?.firstName,
                                          value?.lastName
                                        )
                                      )}
                                    </a>
                                  </td>
                                  <td
                                    className="rating"
                                    style={{
                                      border: "none",
                                      padding: "0 20px",
                                    }}
                                  >
                                    {value?.firstName} {value?.lastName}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          )}

                          {visibleColumns.email && (
                            <td className="table-col">{value?.email}</td>
                          )}
                          {visibleColumns.phoneNumber && (
                            <td className="table-col">{value?.number}</td>
                          )}
                          {visibleColumns.position && (
                            <td className="table-col">{value?.position}</td>
                          )}
                          {visibleColumns.storeName && (
                            <td className="table-col">
                              {value?.store?.storeName}
                            </td>
                          )}
                          {visibleColumns.zipCode && (
                            <td className="table-col">{value?.zipCode}</td>
                          )}
                          {visibleColumns.tspId && (
                            <td className="table-col">{value?.tspId}</td>
                          )}

                          <SheetSelect
                            sheetNumber={sheetNumber}
                            value={value}
                            setData={setData}
                          />

                          <IncidentExportSelect
                            value={value}
                            setData={setData}
                          />

                          {visibleColumns.action && (
                            <td
                              style={{ textAlign: "center" }}
                              className="table-col"
                            >
                              <button className="action-btn">
                                <img
                                  src="images/action_icon.svg"
                                  alt=" "
                                  id={value?.id}
                                  onClick={() => handleAction(value)}
                                />
                              </button>
                              {actionDrop && value.id == viewData?.id && (
                                <div className="action-main">
                                  <ul>
                                    <li
                                      onClick={handleView}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <a className="edit-btn" href="#">
                                        <span>
                                          <img
                                            src="images/action_eye.svg"
                                            alt=""
                                          />
                                        </span>
                                        View Info
                                      </a>
                                    </li>
                                    <li
                                      onClick={handlePermission}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <a className="block-btn">
                                        <span>
                                          <MdOutlineEdit />
                                        </span>
                                        Edit
                                      </a>
                                    </li>

                                    {/* <li onClick={()=>setDeleteMember(true)}  style={{cursor:"pointer"}}>
                            <a className="block-btn" href="#">
                              <span
                                ><img
                                  src="images/action_delete_icon.svg"
                                  alt="" /></span
                              >Delete</a
                            >
                          </li> */}
                                  </ul>
                                </div>
                              )}
                            </td>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      <Pagination
        paramsData={params}
        total={data?.total}
        pageCount={data?.totalPages}
        handlePageChange={pageHandler}
        handlePageSelect={null}
        increasePageSize={false}
      />
    </>
  );
};

export default WebsiteUsers;
