import React, { useEffect, useRef, useState } from "react";
import {
  addPermission,
  addSToreTypes,
  addUser,
  getSheets,
  getStores,
} from "../../config/api";
import toast from "react-hot-toast";
import Loading from "./Loading";

interface Store {
  id: string;
  storeName: string;
}

const AddUser = ({ closeHandle, viewData, setAddUser, setViewData }: any) => {
  const [selectedImage, setSelectedImage] = useState<any>();
  const [dealer, setDealer] = useState("Select");
  const [selectedStore, setSelectedStore] = useState("");
  const [positionShortName, setPositionShortName] = useState("");
  const [position, setPosition] = useState(false);
  const [planSearchString, setPlanSearchString] = useState<string>("");
  const [stores, setStores] = useState<Store[]>([]);
  const [isTypeLoading, setIsTypeLoading] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [permission, setPermission] = useState({
    calculator: "",
    incidentExport: "",
    sheetNumber: "",
  });

  const [sheetNumber, setSheetNumber] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [sheetIds, setSheetIds] = useState<any[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  const togglePermission = (permission: any) => {
    setSelectedPermissions((prevPermissions) => {
      if (permission === "Add/Edit") {
        if (prevPermissions.includes("Add/Edit")) {
          // If "Add/Edit" is already selected, remove it
          return prevPermissions.filter((perm) => perm !== "Add/Edit");
        } else {
          // Add "Add/Edit" and ensure "View" is selected
          return [...new Set([...prevPermissions, "View", "Add/Edit"])];
        }
      } else if (permission === "View") {
        if (prevPermissions.includes("View")) {
          // If "View" is already selected, remove it unless "Add/Edit" is selected
          return prevPermissions.includes("Add/Edit")
            ? prevPermissions
            : prevPermissions.filter((perm) => perm !== "View");
        } else {
          // Add "View"
          return [...prevPermissions, "View"];
        }
      } else {
        if (prevPermissions.includes(permission)) {
          return prevPermissions.filter((perm) => perm !== permission);
        } else {
          return [...prevPermissions, permission];
        }
      }
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const res: any = await getSheets();
        if (res?.status) {
          setSheetNumber(res?.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    })();
  }, []);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    storeId: "",
    storeZipCode: "",
    tspId: 0,
  });

  useEffect(() => {
    if (viewData) {
      setPermission({
        ...permission,
        calculator: viewData?.permissions?.calculator?.calculator,
        incidentExport: viewData.permissions?.incidentExport,
        //   "sheetNumber":viewData?.permissions?.calculator?.sheetNumber
      });
      setSelectedPermissions(viewData?.salesPermissions);
      setSheetIds(viewData?.permissions?.calculator?.sheets || []);
      setData({
        ...data,
        firstName: viewData?.firstName,
        lastName: viewData?.lastName,
        email: viewData?.email,
        phoneNumber: viewData?.number,
        storeId: viewData?.store?.id,
        storeZipCode: viewData?.zipCode,
        tspId: viewData?.tspId,
      });
      setDealer(viewData.position);
      setPositionShortName(viewData.positionShortName);
      setSelectedImage(viewData?.image);
      setSelectedStore(viewData.store?.storeName || "");
    }
  }, [viewData]);
  const handleSelectStore = (storeId: string, storeName: string) => {
    setData({
      ...data,
      storeId,
    });
    setSelectedStore(storeName);
    setActiveDropdown(false);
  };

  //* profile change
  const handleImage = (event: any) => {
    const file = event.target?.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name == "phoneNumber") {
      if (value.length > 10) return;
    }

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSelect = (value: string, shortName: string) => {
    setDealer(value);
    setPositionShortName(shortName);
    setPosition(false);
  };

  const roles = [
    { title: "Dealer/Owner", abbreviation: "DLR" },
    { title: "District Manager", abbreviation: "DM" },
    { title: "Retail Store Manager", abbreviation: "RSM" },
    { title: "Retail Store Assistant Manager", abbreviation: "RAM" },
    { title: "Retail Store Associate", abbreviation: "RSA" },
    { title: "Operations", abbreviation: "OPR" },
  ];

  const handleCalculator = (value: string) => {
    // setPermission({...permission , "sheetNumber":""})
    setSheetIds([]);
    if (permission.calculator == value) {
      setPermission({ ...permission, calculator: "", sheetNumber: "" });
    } else {
      setPermission({ ...permission, calculator: value, sheetNumber: "" });
    }
  };

  const handleIssueExport = (value: string) => {
    if (value == permission.incidentExport) {
      setPermission({ ...permission, incidentExport: "" });
    } else {
      setPermission({ ...permission, incidentExport: value });
    }
  };

  const handleSheetName = (value: any) => {
    const isIdPresent = sheetIds.find(
      (item) => item?.sheetId == value?.sheetId
    );
    if (isIdPresent) {
      const filterIds = sheetIds.filter((item: any) => {
        return item?.sheetId !== value?.sheetId;
      });
      setSheetIds(filterIds);
    } else {
      setPermission({ ...permission, sheetNumber: value?.name });
      const obj = {
        sheetNumber: value?.name,
        sheetId: value?.sheetId,
      };
      setSheetIds([...sheetIds, obj]);
    }

    // if (value == permission?.sheetNumber) {
    //     setPermission({ ...permission, "sheetNumber": "" })
    //     setSheetIds([])
    // } else {
    //     setPermission({ ...permission, "sheetNumber": value?.name })
    //     setSheetIds([value?.sheetId])
    // }
  };

  const handleSubmit = async () => {
    for (let key in data) {
      if (data[key as keyof typeof data] === "") {
        return toast.error("All fields required");
      }
    }
    if (dealer == "Select") {
      return toast.error("All fields required");
    }

    if (permission.calculator == "Yes" && sheetIds.length == 0) {
      return toast.error("SheetNumber is required!");
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailRegex.test(data.email);

    if (!isValid) {
      return toast.error("Please enter valid email!");
    }

    const formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("email", data.email);
    formData.append("position", dealer);
    formData.append("tspId", data.tspId.toString());
    formData.append("storeId", data.storeId);
    formData.append("positionShortName", positionShortName);
    formData.append("storeZipCode", data.storeZipCode);
    formData.append("calculator", permission.calculator);
    formData.append("incidentExport", permission.incidentExport);
    selectedPermissions?.forEach((permission) => {
      formData.append("salesPermissions", permission);
    });
    //   formData.append("sheetNumber" , permission.sheetNumber)

    if (viewData) {
      if (typeof selectedImage === "object") {
        formData.append("newCustomerImage", selectedImage);
      } else {
        formData.append("oldImageUrl", selectedImage);
      }
    } else {
      formData.append("profileImage", selectedImage);
    }

    formData.append("sheets", JSON.stringify(sheetIds));

    try {
      setLoading(true);
      if (viewData) {
        const res: any = await addPermission(formData, viewData?.id);
      } else {
        let res = await addUser(formData);
      }
      setLoading(false);
      closeHandle();
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const getAllStores = async () => {
    try {
      const resp: any = await getStores(planSearchString);
      console.log(resp);
      setStores(resp?.data || []);
    } catch (error) {
      console.error("Error in fetching stores", error);
    }
  };
  useEffect(() => {
    getAllStores();
  }, [planSearchString]);

  const handleAddType = async () => {
    if (!planSearchString) {
      toast.error("Please enter type.");
      return;
    }
    setIsTypeLoading(true);
    try {
      const data = {
        storeName: planSearchString.trim(),
      };
      const response: any = await addSToreTypes(data);
      console.log(response);
      setIsTypeLoading(false);
      getAllStores();
      setPlanSearchString("");
      setSelectedStore(response?.data?.storeName);
      setActiveDropdown(false);
      const newStoreId = response?.data?.id;
      if (newStoreId) {
        setData((prevData) => ({
          ...prevData,
          storeId: newStoreId,
        }));
      }
    } catch (error) {
      setIsTypeLoading(false);
      if (typeof error === "object" && error !== null && "message" in error) {
        console.error(
          "Error fetching sales entry detail:",
          (error as { message: string }).message
        );
        toast.error((error as { message: string }).message);
      } else {
        console.error("Unexpected error:", error);
        toast.error("An unexpected error occurred.");
      }
    }
  };
  console.log(data, "========");

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setActiveDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOpenDropdown = () => {
    setActiveDropdown(!activeDropdown);
  };

  const defaultImage = "images/img_placeholder.png";
  return (
    <>
      <div className="add-employe-overlay">
        <div className="add-employee-main">
          <button
            className="close-btn"
            onClick={() => {
              setViewData(null);
              setAddUser(false);
            }}
          >
            <img src="images/times_icon.svg" alt="" />
          </button>
          <div className="head">
            <h3>{viewData ? "Edit" : "Add"} User</h3>
          </div>
          <div className="add-employee-details">
            <div className="profile">
              <div className="profile-img">
                <img
                  src={
                    selectedImage == null || selectedImage == ""
                      ? defaultImage
                      : typeof selectedImage === "object"
                      ? URL.createObjectURL(selectedImage)
                      : selectedImage
                  }
                  alt=""
                />
                <button className="delete-btn">
                  <img src="images/edit_icon.svg" alt="" />
                  <input type="file" onChange={handleImage} />
                </button>
              </div>
              <label>Update Picture</label>
            </div>
            <div className="employee-info-fill">
              <div className="input-group">
                <div className="input-field">
                  <label>First Name</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    onChange={handleChange}
                    value={data.firstName}
                  />
                </div>
                <div className="input-field">
                  <label>Last Name</label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    onChange={handleChange}
                    value={data.lastName}
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="input-field">
                  <label>Position</label>
                  <div style={{ position: "relative" }}>
                    <button onClick={() => setPosition(!position)}>
                      {dealer} <img src="images/angle_down_grey.svg" />
                    </button>
                    {/* <span className="icon"><img src="images/new_user_icon.svg" alt="" /></span> */}
                    {position && (
                      <div className="dropdown-list-main">
                        <ul>
                          {roles.map((value) => (
                            <li
                              key={value.abbreviation}
                              onClick={() =>
                                handleSelect(value.title, value.abbreviation)
                              }
                            >
                              <span>{value.title}</span>
                              <span>{value.abbreviation}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="input-field">
                  <label>Email</label>
                  <input
                    type="mail"
                    placeholder="abc@gmail.com"
                    name="email"
                    onChange={handleChange}
                    value={data.email}
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="input-field">
                  <label>Phone Number</label>
                  <input
                    type="number"
                    placeholder="Mobile Number"
                    name="phoneNumber"
                    onChange={handleChange}
                    value={data.phoneNumber}
                  />
                </div>
                <div className="input-field" ref={dropdownRef}>
                  <label>Store</label>
                  <button type="button" onClick={handleOpenDropdown}>
                    {selectedStore ? selectedStore : "Select"}
                    <img src="images/angle_down_grey.svg" alt="dropdown" />
                  </button>
                  {activeDropdown && (
                    <div className="carrier-search-main-data active">
                      <div className="search-bar">
                        <input
                          type="search"
                          placeholder="Search store"
                          name="searchString"
                          value={planSearchString}
                          onChange={(e) => setPlanSearchString(e.target.value)}
                        />
                        <button
                          type="button"
                          name="addBtn"
                          onClick={handleAddType}
                        >
                          {" "}
                          {isTypeLoading ? <Loading /> : "+Add"}
                        </button>
                      </div>

                      <ul>
                        {stores.map((store, index) => (
                          <li
                            key={index}
                            onClick={() =>
                              handleSelectStore(store.id, store?.storeName)
                            }
                          >
                            {store?.storeName}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="input-group">
                <div className="input-field">
                  <label>Store Zip Code</label>
                  <input
                    type="text"
                    placeholder="Store Zip Code"
                    name="storeZipCode"
                    onChange={handleChange}
                    value={data.storeZipCode}
                  />
                </div>
                <div className="input-field">
                  <label>TSP ID</label>
                  <input
                    type="text"
                    placeholder="TSP ID"
                    name="tspId"
                    onChange={handleChange}
                    value={data.tspId}
                    pattern="\d*"
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      target.value = target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="input-field">
                  <label>Sales Permission</label>
                  <div className="calc-select">
                    <button
                      className={
                        selectedPermissions?.includes("View") ? "active" : ""
                      }
                      onClick={() => togglePermission("View")}
                    >
                      View
                    </button>
                    <button
                      className={
                        selectedPermissions?.includes("Add/Edit")
                          ? "active"
                          : ""
                      }
                      onClick={() => togglePermission("Add/Edit")}
                    >
                      Add/Edit
                    </button>
                  </div>
                </div>
                <div className="input-field">
                  <label>Incident Export</label>
                  <div className="calc-select">
                    <button
                      className={
                        permission?.incidentExport == "Yes" ? "active" : ""
                      }
                      onClick={() => handleIssueExport("Yes")}
                    >
                      Yes
                    </button>
                    <button
                      className={
                        permission?.incidentExport == "No" ? "active" : ""
                      }
                      onClick={() => handleIssueExport("No")}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
              <div className="input-group">
                <div className="input-field">
                  <label>Calculator</label>
                  <div className="calc-select">
                    <button
                      className={
                        permission?.calculator == "Yes" ? "active" : ""
                      }
                      onClick={() => handleCalculator("Yes")}
                    >
                      Yes
                    </button>
                    <button
                      className={permission?.calculator == "No" ? "active" : ""}
                      onClick={() => handleCalculator("No")}
                    >
                      No
                    </button>
                  </div>
                </div>
                {permission?.calculator == "Yes" && (
                  <div className="input-field">
                    <label>Sheet number</label>
                    <div className="sheet-select">
                      {sheetNumber &&
                        sheetNumber?.map((value: any) => {
                          const isActive = sheetIds.some(
                            (item) => item?.sheetId === value?.sheetId
                          );

                          return (
                            <>
                              <button
                                onClick={() => handleSheetName(value)}
                                className={isActive ? "active" : ""}
                              >
                                {value?.name}
                              </button>
                            </>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="add-employee-btn">
            <button
              className="cancle-btn"
              onClick={() => {
                setAddUser(false);
                setViewData(null);
              }}
            >
              Cancel
            </button>
            <button
              className="add-btn"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <Loading /> : viewData ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
