import React, { Fragment, useEffect, useRef, useState } from "react";
import Header from "../../components/common/Header";
import { useNavigate } from "react-router-dom";
import {
  addWebSalesEntry,
  getAllCustomers,
  getWebCarrier,
  getWebModel,
  getWebPlans,
  getWebPort,
  grammarPrompt,
} from "../../config/api";
import Loading from "../../components/common/Loading";
import toast from "react-hot-toast";
import grammarIcon from "../../assets/svg/grammaricon.svg";
import { useBlocker } from "../../utils/useBlocker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

interface Customer {
  id: string;
  name: string;
}

const AddALine = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [displayDate, setDisplayDate] = useState<string>("");
  const [addCustomer, setAddCustomer] = useState(true);
  const initialFormData = {
    autopay: false,
    lineNumber: NaN,
    carrier: {
      id: "",
      name: "",
    },
    idVerify: false,
    imei: "",
    plan: {
      id: "",
      name: "",
      value: "",
    },
    vpOrderNumber: "",
    modelSku: {
      id: "",
      name: "",
    },
    monthly: "",
    notes: "",
    number: "",
    pins: "",
    port: {
      id: "",
      name: "",
    },
    sim: "",
    today: "",
    type: "",
  };

  const [lines, setLines] = useState([initialFormData]);
  const [grammarLoading, setGrammarLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [customerDropDown, setCustomerDropdown] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<number>();
  const [searchString, setSearchString] = useState<string>("");
  const [customerString, setCustomerString] = useState("");
  const [planSearchString, setPlanSearchString] = useState<string>("");
  const [portSearchString, setPortSearchString] = useState<string>("");
  const [carrierSearchString, setCarrierSearchString] = useState<string>("");
  const [activeName, setActiveName] = useState<string>("");
  const [carriers, setCarriers] = useState<any[]>([]);
  const [models, setModels] = useState<any[]>([]);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const [plans, setPlans] = useState<any[]>([]);
  const [ports, setPorts] = useState<any[]>([]);
  const planInputRef = useRef<HTMLInputElement | null>(null);
  const portInputRef = useRef<HTMLInputElement | null>(null);
  const carrierInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<{
    id: string | null;
    name: string | null;
  }>({ id: null, name: null });

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setActiveDropdown(-1);
      setActiveName("");
      setCustomerDropdown(false);
    }
  };

  const handleCustomerChange = (customer: { id: string; name: string }) => {
    // const selectedId = id;
    // const selectedCustomer = customers.find((customer) => customer.id === id);

    setSelectedCustomer(customer);
    setCustomerDropdown(false);
    setFirstName("");
    setLastName("");
    setCustomerEmail("");
    setCustomerAddress("");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const carriersResponse: any = await getWebCarrier(carrierSearchString);
        setCarriers(carriersResponse.data || []);
        const modelsResponse: any = await getWebModel(searchString);
        setModels(modelsResponse.data || []);
        const plansResponse: any = await getWebPlans(planSearchString);
        setPlans(plansResponse.data || []);
        const portsResponse: any = await getWebPort(portSearchString);
        setPorts(portsResponse.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [searchString, planSearchString, portSearchString, carrierSearchString]);

  const handleChange = (e: any, index: number) => {
    const { name, value, type, checked } = e.target;
    setActiveDropdown(-1);
    setActiveName("");
    const updatedLines: any = [...lines];

    if (name === "lineNumber" && !isNaN(Number(value))) {
      const newLineNumber = value === "" ? null : Number(value);
      updatedLines[index] = {
        ...updatedLines[index],
        [name]: newLineNumber,
      };

      for (let i = index + 1; i < updatedLines.length; i++) {
        updatedLines[i] = {
          ...updatedLines[i],
          lineNumber: updatedLines[i - 1].lineNumber + 1,
        };
      }
    } else if (name.includes(".")) {
      const [parent, child] = name.split(".");
      updatedLines[index] = {
        ...updatedLines[index],
        [parent]: {
          ...updatedLines[index][parent],
          [child]: type === "checkbox" ? checked : value,
        },
      };
    } else {
      updatedLines[index] = {
        ...updatedLines[index],
        [name]: type === "checkbox" ? checked : value,
      };
    }

    setLines(updatedLines);
    setIsDirty(true);
  };

  const validateForm = () => {
    if (
      !selectedCustomer?.id &&
      (firstName.trim() === "" ||
        customerEmail.trim() === "" ||
        customerAddress.trim() === "")
    ) {
      toast.error("Customer information is required.");
      return false;
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (customerEmail.trim() !== "" && !emailRegex.test(customerEmail.trim())) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    if (!displayDate) {
      toast.error("Display date is required");
      return false;
    }

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      if (!line.lineNumber) {
        toast.error(`Line Number is required for line ${i + 1}.`);
        return false;
      }
      if (!line.number || line.number.trim() === "") {
        toast.error(`Phone Number is required for line ${i + 1}.`);
        return false;
      } else if (line.number.trim().length !== 10) {
        toast.error(
          `Phone Number must be exactly 10 digits for line ${i + 1}.`
        );
        return false;
      }
      if (!line.modelSku || line.modelSku.name.trim() === "") {
        toast.error(`Model SKU is required for line ${i + 1}.`);
        return false;
      }
      if (!line.imei || line.imei.trim() === "") {
        toast.error(`IMEI is required for line ${i + 1}.`);
        return false;
      }
      if (!line.sim || line.sim.trim() === "") {
        toast.error(`SIM is required for line ${i + 1}.`);
        return false;
      }
      if (!line.carrier || line.carrier.name?.trim() === "") {
        toast.error(`Carrier is required for line ${i + 1}.`);
        return false;
      }
      if (!line.port || line.port.name?.trim() === "") {
        toast.error(`Port is required for line ${i + 1}.`);
        return false;
      }
      if (isNaN(parseFloat(line.today)) || parseFloat(line.today) < 0) {
        toast.error(`Today's amount must be a valid number for line ${i + 1}.`);
        return false;
      }
      if (isNaN(parseFloat(line.monthly)) || parseFloat(line.monthly) < 0) {
        toast.error(`Monthly amount must be a valid number for line ${i + 1}.`);
        return false;
      }
      if (!line.vpOrderNumber) {
        toast.error(`VP Order Number is required for line ${i + 1}.`);
        return false;
      }
    }

    return true;
  };

  const formatDate = (date: any) => {
    const d = new Date(date);
    const day = String(d.getUTCDate()).padStart(2, "0");
    const month = String(d.getUTCMonth() + 1).padStart(2, "0");
    const year = d.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  console.log("selectedCustomer", selectedCustomer);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsDirty(false);
    setIsButtonLoading(true);
    try {
      const data = lines.map((line, idx) => ({
        autopay: line.autopay,
        carrier: line.carrier,
        idVerify: line.idVerify,
        imei: line.imei.trim(),
        lineNumber: parseInt(line.lineNumber.toString()) || 0,
        modelSku: line.modelSku,
        vpOrderNumber: parseInt(line.vpOrderNumber.toString()) || 0,
        monthly: parseFloat(line.monthly.toString()) || 0,
        notes: line.notes,
        number: line.number,
        pins: line.pins,
        plan: line.plan,
        port: line.port,
        sim: line.sim.trim(),
        today: parseInt(line.today.toString()) || 0,
        type: "addALine",
      }));

      const dateToSend = {
        displayDate: formatDate(displayDate),
        newCustomer: {
          address: customerAddress ? customerAddress : null,
          email: customerEmail ? customerEmail : null,
          firstName: firstName ? firstName : null,
          lastName: lastName ? lastName : null,
        },
        oldCustomerId:
          selectedCustomer?.id != null ? selectedCustomer?.id : null,
        type: selectedCustomer?.id != null ? "oldCustomer" : "newCustomer",
        sales: data,
      };

      const response: any = await addWebSalesEntry(dateToSend);
      navigate("/website-sales");
      setIsButtonLoading(false);
    } catch (error: any) {
      console.error("Error updating sales entry:", error);
      setIsButtonLoading(false);
      toast.error(error?.message);
    } finally {
      setIsButtonLoading(false);
      setIsDirty(true);
    }
  };

  const addLine = () => {
    const lastLineNumber =
      lines.length > 0 ? Number(lines[lines.length - 1].lineNumber) : 0;
    const nextLineNumber = lastLineNumber + 1;
    const newLine = {
      ...initialFormData,
      lineNumber: nextLineNumber,
    };

    setLines([...lines, newLine]);
  };

  const handleBack = () => {
    navigate("/sale-entry");
  };

  const handleOpenDropdown = (index: number, name: string) => {
    setActiveDropdown(index);
    setActiveName(name);
  };

  const handleOpenCustomerDropdown = () => {
    setCustomerDropdown(!customerDropDown);
  };

  const handleOpenDropdownFocus = (index: number, name: string) => {
    if (activeDropdown !== index || activeName !== name) {
      setActiveDropdown(index);
      setActiveName(name);

      setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 0);
      setTimeout(() => {
        if (planInputRef.current) {
          planInputRef.current.focus();
        }
      }, 0);
      setTimeout(() => {
        if (portInputRef.current) {
          portInputRef.current.focus();
        }
      }, 0);
      setTimeout(() => {
        if (carrierInputRef.current) {
          carrierInputRef.current.focus();
        }
      }, 0);
    }
  };

  const handleGrammar = async () => {
    try {
      setGrammarLoading(true);
      const updatedLines = await Promise.all(
        lines.map(async (line) => {
          if (line?.notes) {
            try {
              const res: any = await grammarPrompt(line?.notes);
              if (res?.status) {
                return { ...line, notes: res?.message || line?.notes };
              }
            } catch (error) {
              console.error("Error processing line notes:", error);
              return line;
            }
          }
          return line;
        })
      );
      setLines(updatedLines);
    } catch (error) {
      console.error("Error in handleGrammar:", error);
    } finally {
      setGrammarLoading(false);
    }
  };

  const [isDirty, setIsDirty] = useState(false);

  const handleBlock = () => {
    if (isDirty) {
      return !window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
    }
    return false;
  };

  useBlocker(handleBlock, isDirty);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {
      try {
        const response: any = await getAllCustomers(customerString);
        setCustomers(response?.data);
      } catch (error) {
        console.error("Error getting customers:", error);
      }
    }, 1000);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [customerString]);

  const handleAddCustomer = () => {
    setAddCustomer(!addCustomer);
    setSelectedCustomer({ id: null, name: null });
  };

  const handleDateChange = (date: Dayjs | null) => {
    console.log("Received date:", date);
    const formattedDate = date ? dayjs(date).format("MM-DD-YYYY") : "";
    setDisplayDate(formattedDate);
  };

  return (
    <Fragment>
      <Header
        title={"Add a Line"}
        backButton={true}
        backHandle={handleBack}
        admin={false}
      />
      <div className="loaction-list-main add-plan-main activation-main">
        <div className="loaction-list-head">
          <div className="add-plan-detail" style={{ padding: "0px" }}>
            <div className="input-group new-customer">
              {!addCustomer && (
                <div className="input-field input-field-data">
                  <>
                    {/* <label>Select Customer</label>
                    <select name="lineNumber" onChange={handleCustomerChange}>
                      <option value="">Select Customer</option>
                      {customers?.map((customer, index) => (
                        <option key={index} value={customer.id}>
                          {customer.name}
                        </option>
                      ))}
                    </select> */}
                    <div className="input-field" ref={dropdownRef}>
                      <label>
                        Select Customer <sup className="required">*</sup>
                      </label>
                      <button
                        type="button"
                        onClick={() => handleOpenCustomerDropdown()}
                      >
                        {selectedCustomer.name || "Select"}{" "}
                        <img src="images/angle_down_grey.svg" alt="dropdown" />
                      </button>
                      {customerDropDown && (
                        <div className="carrier-search-main carrier-search-main-date active">
                          <div className="search-bar">
                            <input
                              type="search"
                              autoComplete="off"
                              placeholder=""
                              name="searchString"
                              value={customerString}
                              onChange={(e) =>
                                setCustomerString(e.target.value)
                              }
                            />
                          </div>
                          <ul>
                            {customers?.map((customer) => (
                              <li
                                key={customer.id}
                                onClick={() => handleCustomerChange(customer)}
                              >
                                {customer.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </>
                </div>
              )}

              {addCustomer && (
                <>
                  <div className="input-field">
                    <label>
                      Customer First Name <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder=""
                    />
                  </div>
                  <div className="input-field">
                    <label>
                      Customer Last Name <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder=""
                    />
                  </div>
                  <div className="input-field">
                    <label>
                      Customer Email <sup className="required">*</sup>
                    </label>
                    <input
                      type="email"
                      autoComplete="off"
                      value={customerEmail}
                      onChange={(e) => setCustomerEmail(e.target.value)}
                      name="lineNumber"
                      placeholder=""
                    />
                  </div>
                  <div className="input-field">
                    <label>
                      Customer Address <sup className="required">*</sup>
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="lineNumber"
                      placeholder=""
                      value={customerAddress}
                      onChange={(e) => setCustomerAddress(e.target.value)}
                      // value={formData.lineNumber}
                      // onChange={(e) => handleChange(e, index)}
                      // disabled={index != 0}
                    />
                  </div>
                </>
              )}

              <div className="input-field">
                <label>
                  Display Date <sup className="required">*</sup>
                </label>
                {/* <input
                  type="date"
                  name="displayDate"
                  placeholder=""
                  value={displayDate}
                  onChange={(e) => setDisplayDate(e.target.value)}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(displayDate)}
                    onChange={(date) => handleDateChange(date)}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <button className="add-customer-button" onClick={handleAddCustomer}>
              {addCustomer ? "Choose customer" : "Add New Customer"}
            </button>
          </div>
        </div>
      </div>

      {lines.map((formData, index) => (
        <div className="loaction-list-main add-plan-main activation-main">
          {index > 0 && (
            <div className="loaction-list-head">
              {index > 0 && <h3>Next Line</h3>}
              {index > 0 && (
                <button
                  type="button"
                  className="remove-btn"
                  onClick={() => setLines(lines.filter((_, i) => i !== index))}
                >
                  Remove
                </button>
              )}
            </div>
          )}

          <form
            onSubmit={handleSubmit}
            className="add-plan-detail"
            autoComplete="off"
          >
            <div className="input-group">
              <div className="input-field">
                <label>Line Number</label>
                <input
                  autoComplete="off"
                  type="number"
                  name="lineNumber"
                  placeholder=""
                  value={formData.lineNumber}
                  onChange={(e) => handleChange(e, index)}
                  disabled={index != 0}
                />
              </div>

              <div className="input-field">
                <label>Plan</label>
                <button
                  type="button"
                  onFocus={() => handleOpenDropdownFocus(index, "Plan")}
                  onClick={() => handleOpenDropdown(index, "Plan")}
                >
                  {formData.plan.value
                    ? `$${formData.plan.value} - ${formData.plan.name}`
                    : "Select"}
                  <img src="images/angle_down_grey.svg" alt="dropdown" />
                </button>
                {activeDropdown === index && activeName === "Plan" && (
                  <div
                    className="carrier-search-main bottom-class-line active"
                    ref={dropdownRef}
                  >
                    <div className="search-bar">
                      <input
                        type="search"
                        autoComplete="off"
                        placeholder="Search Plan Value"
                        name="searchString"
                        value={planSearchString}
                        onChange={(e) => setPlanSearchString(e.target.value)}
                        ref={searchInputRef}
                      />
                    </div>
                    <ul>
                      {plans.map((plan) => (
                        <li
                          key={plan.id}
                          onClick={() =>
                            handleChange(
                              {
                                target: {
                                  name: "plan",
                                  value: {
                                    id: plan.id,
                                    name: plan.name,
                                    value: plan.value,
                                  },
                                },
                              },
                              index
                            )
                          }
                        >
                          ${plan?.value} - {plan?.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <div className="input-field">
                <label>
                  Phone Number<sup className="required">*</sup>
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  name="number"
                  value={formData.number}
                  onChange={(e) => handleChange(e, index)}
                  maxLength={10}
                  pattern="\d*"
                  onInput={(e) => {
                    const target = e.target as HTMLInputElement;
                    target.value = target.value.replace(/[^0-9]/g, "");
                  }}
                />
              </div>

              <div className="input-field">
                <label>
                  Model/SKU <sup className="required">*</sup>
                </label>
                <button
                  type="button"
                  onFocus={() => handleOpenDropdownFocus(index, "Model/SKU")}
                  onClick={() => handleOpenDropdown(index, "Model/SKU")}
                >
                  {formData.modelSku.name || "Select"}{" "}
                  <img src="images/angle_down_grey.svg" alt="dropdown" />
                </button>
                {activeDropdown === index && activeName === "Model/SKU" && (
                  <div
                    className="carrier-search-main bottom-class-model-line active"
                    ref={dropdownRef}
                  >
                    <div className="search-bar">
                      <input
                        autoComplete="off"
                        type="search"
                        placeholder="Search Model, SKU, UPC"
                        name="searchString"
                        value={searchString}
                        ref={searchInputRef}
                        onChange={(e) => setSearchString(e.target.value)}
                      />
                    </div>
                    <ul>
                      {models.map((model) => (
                        <li
                          key={model.id}
                          onClick={() =>
                            handleChange(
                              {
                                target: {
                                  name: "modelSku",
                                  value: { id: model.id, name: model.name },
                                },
                              },
                              index
                            )
                          }
                        >
                          {model.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <div className="input-field">
                <label>
                  IMEI <sup className="required">*</sup>
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  name="imei"
                  placeholder=""
                  value={formData.imei}
                  onChange={(e) => handleChange(e, index)}
                  pattern="\d*"
                  onInput={(e) => {
                    const target = e.target as HTMLInputElement;
                    target.value = target.value.replace(/[^0-9]/g, "");
                  }}
                />
              </div>

              <div className="input-field">
                <label>
                  SIM <sup className="required">*</sup>
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  name="sim"
                  placeholder=""
                  value={formData.sim}
                  onChange={(e) => handleChange(e, index)}
                  pattern="\d*"
                  onInput={(e) => {
                    const target = e.target as HTMLInputElement;
                    target.value = target.value.replace(/[^0-9]/g, "");
                  }}
                />
              </div>

              <div className="input-field">
                <label>
                  Carrier <sup className="required">*</sup>
                </label>
                <button
                  type="button"
                  onFocus={() => handleOpenDropdownFocus(index, "Carrier")}
                  onClick={() => handleOpenDropdown(index, "Carrier")}
                >
                  {formData.carrier.name || "Select"}{" "}
                  <img src="images/angle_down_grey.svg" alt="dropdown" />
                </button>
                {activeDropdown === index && activeName === "Carrier" && (
                  <div
                    className="carrier-search-main bottom-class-model-carrier-line active"
                    ref={dropdownRef}
                  >
                    <div className="search-bar">
                      <input
                        autoComplete="off"
                        type="search"
                        placeholder="Search Carrier"
                        name="searchString"
                        value={carrierSearchString}
                        onChange={(e) => setCarrierSearchString(e.target.value)}
                        ref={carrierInputRef}
                      />
                    </div>
                    <ul>
                      {carriers.map((carrier) => (
                        <li
                          key={carrier.id}
                          onClick={() =>
                            handleChange(
                              {
                                target: {
                                  name: "carrier",
                                  value: { id: carrier.id, name: carrier.name },
                                },
                              },
                              index
                            )
                          }
                        >
                          {carrier.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <div className="input-field">
                <label>
                  Port? <sup className="required">*</sup>
                </label>
                <button
                  type="button"
                  onFocus={() => handleOpenDropdownFocus(index, "Port?")}
                  onClick={() => handleOpenDropdown(index, "Port?")}
                >
                  {formData.port.name || "Select"}
                  <img src="images/angle_down_grey.svg" alt="dropdown" />
                </button>
                {activeDropdown === index && activeName === "Port?" && (
                  <div
                    className="carrier-search-main bottom-class-port-line active"
                    ref={dropdownRef}
                  >
                    <div className="search-bar">
                      <input
                        autoComplete="off"
                        type="search"
                        placeholder="Search Port"
                        name="searchString"
                        value={portSearchString}
                        onChange={(e) => setPortSearchString(e.target.value)}
                        ref={portInputRef}
                      />
                    </div>
                    <ul>
                      {ports.map((port) => (
                        <li
                          key={port.id}
                          onClick={() =>
                            handleChange(
                              {
                                target: {
                                  name: "port",
                                  value: { id: port.id, name: port.name },
                                },
                              },
                              index
                            )
                          }
                        >
                          {port.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <div className="input-field">
                <label>
                  Today <sup className="required">*</sup>
                </label>
                <div className="input-wrapper">
                  <span className="dollar-symbol">$</span>
                  <input
                    autoComplete="off"
                    type="number"
                    name="today"
                    placeholder=""
                    value={formData.today}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
              </div>
              <div className="input-field">
                <label>
                  Monthly <sup className="required">*</sup>
                </label>
                <div className="input-wrapper">
                  <span className="dollar-symbol">$</span>
                  <input
                    autoComplete="off"
                    type="text"
                    name="monthly"
                    placeholder=""
                    value={formData.monthly}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
              </div>

              {/* Pin Input */}
              <div className="input-field">
                <label>Pin</label>
                <input
                  autoComplete="off"
                  type="number"
                  name="pins"
                  placeholder=""
                  value={formData.pins}
                  onChange={(e) => handleChange(e, index)}
                  maxLength={6}
                />
              </div>

              {/* Notes Input */}
              <div className="input-field">
                <label>Notes</label>
                <div style={{ position: "relative" }}>
                  <input
                    autoComplete="off"
                    type="text"
                    name="notes"
                    placeholder=""
                    value={formData.notes}
                    onChange={(e) => handleChange(e, index)}
                  />
                  <span
                    onClick={handleGrammar}
                    style={{
                      position: "absolute",
                      top: "50%",
                      cursor: "pointer",
                      right: "15px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {grammarLoading ? (
                      <span>
                        <Loading />{" "}
                      </span>
                    ) : (
                      <img
                        src={grammarIcon}
                        alt="grammar icon"
                        width={"25px"}
                      />
                    )}
                  </span>
                </div>
              </div>

              <div className="input-field">
                <label>
                  VP Order Number <sup className="required">*</sup>
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  name="vpOrderNumber"
                  placeholder=""
                  value={formData.vpOrderNumber}
                  onChange={(e) => handleChange(e, index)}
                  pattern="\d*"
                  onInput={(e) => {
                    const target = e.target as HTMLInputElement;
                    target.value = target.value.replace(/[^0-9]/g, "");
                  }}
                />
              </div>

              {/* Checkbox Inputs */}
              <div className="input-field check-field">
                <div className="check-input">
                  <label>I.D Veriff</label>
                  <input
                    type="checkbox"
                    name="idVerify"
                    checked={formData.idVerify}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
                <div className="check-input">
                  <label>AutoPay</label>
                  <input
                    type="checkbox"
                    name="autopay"
                    checked={formData.autopay}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
              </div>
            </div>

            {lines.length === index + 1 && (
              <div className="submit-btns">
                <button
                  type="button"
                  onClick={addLine}
                  disabled={isButtonLoading}
                >
                  ADD A LINE
                </button>
                <button
                  type="submit"
                  className="save-btn"
                  disabled={isButtonLoading}
                >
                  {isButtonLoading ? <Loading /> : "SUBMIT"}
                </button>
              </div>
            )}
          </form>
        </div>
      ))}
    </Fragment>
  );
};

export default AddALine;
