import { CSSProperties } from "react";

export const getToken = () => {
  return localStorage.getItem("token");
};
export const getRole = () => {
  return localStorage.getItem("role");
};
export const getUserId = () => {
  return localStorage.getItem("userId");
};

export const getStoreId = () => {
  return localStorage.getItem("storeId");
};

export const generateInitials = (name: string, lastName: string) => {
  let actualValue = "";
  if (name || lastName) {
    if (name.length > 0) {
      actualValue = name.charAt(0);
    }
    if (lastName.length > 0) {
      actualValue = actualValue + lastName.charAt(0);
    }
  }

  if (actualValue) {
    return actualValue.toUpperCase();
  }
  return "";
};

export function getPaginationText(
  currentPage: number,
  itemsPerPage: number,
  totalItems: number
) {
  if (totalItems === 0) {
    return "Showing 0 Results";
  }

  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  return `Showing ${startItem} to ${endItem} of ${totalItems} items`;
}

export function formatDate(isoString: string) {
  const date = new Date(isoString);
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone: "UTC",
  };

  return date.toLocaleString("en-US", options);
}

export function formatTime(isoString: string) {
  const date = new Date(isoString);
  const options: any = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "UTC",
  };

  return date.toLocaleString("en-US", options);
}

export function textEllipsis(text: any, maxLength: any) {
  if (text?.length <= maxLength) {
    return text;
  }

  // Make sure the maxLength includes space for the ellipsis characters
  const ellipsis = "...";
  const truncatedText = text?.slice(0, maxLength - ellipsis.length);

  return truncatedText + ellipsis;
}

export const convertToEasternFormat = (isoDate: any) => {
  const date = new Date(isoDate);

  // Extract date components
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  // Extract time components
  // let hours = date.getHours();
  // const minutes = String(date.getMinutes()).padStart(2, '0');
  // const ampm = hours >= 12 ? 'PM' : 'AM';

  // hours = hours % 12;
  // hours = hours ? hours : 12; // the hour '0' should be '12'
  // const formattedHours = String(hours).padStart(2, '0');

  // Format date and time separately
  return `${month}/${day}/${year}`;
  // setFormattedTime(`${formattedHours}:${minutes} ${ampm}`);
};

export const convertToEasternFormatTime = (isoDate: any) => {
  const date = new Date(isoDate);

  // Extract date components
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  // Extract time components
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedHours = String(hours).padStart(2, "0");

  // Format date and time separately
  // return `${month}/${day}/${year}`
  return `${formattedHours}:${minutes} ${ampm}`;
  // setFormattedTime(`${formattedHours}:${minutes} ${ampm}`);
};

export function maskNumber(value: any) {
  // Convert phone number to a string (in case it's passed as a number)
  if (!value) return;
  value = value.toString();

  // Get the length of the phone number
  const length = value?.length;

  // // Ensure the phone number has at least 4 digits
  // if (length < 4) {
  //     return value; // If it's less than 4 digits, return the number as is
  // }

  // Replace all but the last 4 digits with stars
  const maskedNumber = "*".repeat(length - 4) + value.slice(-4);

  return maskedNumber;
}

export const planHead = [
  { label: "Name", className: "heading", style: {} as CSSProperties },
  { label: "Code", className: "heading", style: {} as CSSProperties },
  { label: "Value", className: "heading", style: {} as CSSProperties },
  { label: "Type", className: "heading", style: {} as CSSProperties },
  { label: "Start Date", className: "heading", style: {} as CSSProperties },
  { label: "End Date", className: "heading", style: {} as CSSProperties },
  // {
  //   label: "Action",
  //   className: "heading",
  //   style: { width: "7%", textAlign: "center" } as CSSProperties,
  // },
];

export const ModelHead = [
  { label: "Common Name", className: "heading", style: {} as CSSProperties },
  { label: "Name", className: "heading", style: {} as CSSProperties },
  { label: "SKU", className: "heading", style: {} as CSSProperties },
  { label: "UPC", className: "heading", style: {} as CSSProperties },
  { label: "Product ID", className: "heading", style: {} as CSSProperties },
  { label: "Kitt", className: "heading", style: {} as CSSProperties },
  { label: "Type", className: "heading", style: {} as CSSProperties },
  { label: "Start Date", className: "heading", style: {} as CSSProperties },
  { label: "End Date", className: "heading", style: {} as CSSProperties },
  // {
  //   label: "Action",
  //   className: "heading",
  //   style: { width: "7%", textAlign: "center" } as CSSProperties,
  // },
];

export const CarriewHead = [
  { label: "Plan Dropdown", className: "heading", style: {} as CSSProperties },
  // {
  //   label: "Action",
  //   className: "heading",
  //   style: { width: "7%", textAlign: "center" } as CSSProperties,
  // },
];

export const PortHead = [
  { label: "Name", className: "heading", style: {} as CSSProperties },
  { label: "Type", className: "heading", style: {} as CSSProperties },
  { label: "Start Date", className: "heading", style: {} as CSSProperties },
  { label: "End Date", className: "heading", style: {} as CSSProperties },
  // {
  //   label: "Action",
  //   className: "heading",
  //   style: { width: "7%", textAlign: "center" } as CSSProperties,
  // },
];

export const formatDateNew = (date: any) => {
  const d = new Date(date);
  const day = String(d.getUTCDate()).padStart(2, "0");
  const month = String(d.getUTCMonth() + 1).padStart(2, "0");
  const year = d.getUTCFullYear();
  return `${month}/${day}/${year}`;
};
