import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import { useNavigate, useParams } from "react-router-dom";
import { getIncidentDetails } from "../config/api";
import IncidentView from "../website/pages/IncidentView";
import PageLoading from "../components/common/PageLoading";
import { generateInitials, textEllipsis } from "../utils/common";
import ImageModal from "../components/common/ImageModal";

const AdminIncidentView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          setLoading(true);
          let res: any = await getIncidentDetails(id);
          if (res?.status) {
            setLoading(false);
            setData(res?.data);
          }
        } catch (error) {
          setLoading(false);
          console.log("error", error);
        }
      })();
    }
  }, [id]);
  return (
    <>
      <Header
        title={"Incident detail"}
        backButton={true}
        backHandle={() => {
          return navigate(-1);
        }}
        admin={true}
      />

      {loading ? (
        <PageLoading />
      ) : (
        <>
          <IncidentView viewData={data?.incidentInfo} />
          <div className="incident-user-info">
            <div className="head">
              <h3>User Info</h3>
            </div>
            <div className="info">
              <div className="profile">
                {data?.userInfo?.image ? (
                  <>
                    <img src={data?.userInfo?.image} alt="" />
                  </>
                ) : (
                  generateInitials(
                    data?.userInfo?.firstName,
                    data?.userInfo?.lastName
                  )
                )}
              </div>
              <div className="details">
                <div className="input-group">
                  <div className="input-field">
                    <label>First Name</label>
                    <input
                      type="text"
                      placeholder="Henry"
                      disabled
                      value={data?.userInfo?.firstName}
                    />
                  </div>
                  <div className="input-field">
                    <label>Last Name</label>
                    <input
                      type="text"
                      placeholder="Estward"
                      disabled
                      value={data?.userInfo?.lastName}
                    />
                  </div>
                  <div className="input-field">
                    <label>Position</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        placeholder="Dealer / Owner"
                        disabled
                        value={textEllipsis(data?.userInfo?.position, 25)}
                      />
                      <span>{data?.userInfo?.positionShortName}</span>
                    </div>
                  </div>
                  <div className="input-field">
                    <label>Email</label>
                    <input
                      type="email"
                      placeholder="abcde@gmail.com"
                      disabled
                      value={data?.userInfo?.email}
                    />
                  </div>
                  <div className="input-field">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      placeholder="11111111111"
                      disabled
                      value={data?.userInfo?.number}
                    />
                  </div>
                  <div className="input-field">
                    <label>Store Name</label>
                    <input
                      type="text"
                      placeholder="Name"
                      disabled
                      value={data?.userInfo?.store?.storeName}
                    />
                  </div>
                  <div className="input-field">
                    <label>Store Zip Code</label>
                    <input
                      type="text"
                      placeholder="111111"
                      disabled
                      value={data?.userInfo?.zipCode}
                    />
                  </div>
                  <div className="input-field">
                    <label>TSP ID</label>
                    <input
                      type="text"
                      placeholder="11111111111"
                      disabled
                      value={data?.userInfo?.tspId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* </div> */}
    </>
  );
};

export default AdminIncidentView;
