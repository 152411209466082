import React, { useState, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import RenderHeader from "./renderHeader";
import moment from "moment";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import Loading from "../components/common/Loading";
import toast from "react-hot-toast";
import { deleteSales } from "../config/api";
import { textAlign } from "@mui/system";
import { formatDateNew } from "../utils/common";

const gridStyle = { minHeight: 550, width: "100vw" };

const CustomDataTable = ({
  dataSource,
  visibleColumns,
  filters,
  setFilters,
  onSortChange,
  sortBy,
  sortOrder,
  selectedSalesType,
  visibliltColumns,
  fetchSalesListing,
  handleClickViewButton,
  handleDeleteOpen,
  handleClickEditButton,
}: any) => {
  // const [filteredData, setFilteredData] = useState(dataSource || []);
  const [copyMessage, setCopyMessage] = useState("");
  const [menuVisibility, setMenuVisibility] = useState<Record<string, boolean>>(
    {}
  );
  console.log("-----dataSource--------------", dataSource);

  //   useEffect(() => {
  //   console.log("Filtered data updated:", filteredData);
  // }, [filteredData]);

  // useEffect(() => {
  //   fetchFilteredData(filters);
  // }, [filters, dataSource]);

  // Sorting effect
  // useEffect(() => {
  //   if (sortBy && sortOrder) {
  //     const sortedData = [...filteredData].sort((a, b) => {
  //       if (a[sortBy] < b[sortBy]) return sortOrder === "ascending" ? -1 : 1;
  //       if (a[sortBy] > b[sortBy]) return sortOrder === "ascending" ? 1 : -1;
  //       return 0;
  //     });
  //     setFilteredData(sortedData);
  //   }
  // }, [sortBy, sortOrder, filteredData]);

  // const fetchFilteredData = (filters: Record<string, string[]>) => {
  //   console.log("==========filters===========",filters)
  //   const filtered = dataSource.filter((row: any) =>
  //     Object.entries(filters).every(([key, values]) => {
  //       if (!values || values.length === 0) return true;
  //       if (key === "dateTime") {
  //         const rowValue = moment(row[key]).format("MM/DD/YYYY hh:mm A");
  //         return values.includes(rowValue);
  //       }
  //       return values.includes(String(row[key]));
  //     })
  //   );
  //   console.log("--------------main-----",filtered)

  //   setFilteredData(filtered);
  // };

  // Handle filter changes
  const handleFilterChange = (
    columnName: string,
    selectedOptions: string[]
  ) => {
    const updatedFilters = { ...filters, [columnName]: selectedOptions };

    Object.keys(updatedFilters).forEach((key) => {
      if (!updatedFilters[key] || updatedFilters[key].length === 0) {
        delete updatedFilters[key];
      }
    });

    setFilters(updatedFilters);
  };

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      setCopyMessage("Copied!");
      setTimeout(() => setCopyMessage(""), 2000);
    });
  };

  const handleSortChange = (newSortBy: string, newSortOrder: string) => {
    onSortChange(newSortBy, newSortOrder);
  };

  const handleActionClick = (rowData: any) => {
    const newVisibility = { ...menuVisibility };

    if (menuVisibility[rowData.id]) {
      newVisibility[rowData.id] = false;
    } else {
      Object.keys(newVisibility).forEach((key) => {
        newVisibility[key] = false;
      });
      newVisibility[rowData.id] = true;
    }

    setMenuVisibility(newVisibility);
    console.log("Action button clicked for:", rowData);
  };

  // Columns enhancement for including the action button and menu
  const enhancedColumns = visibleColumns.map((col: any) => {
    const isColumnVisible = visibleColumns[col.name] !== false;

    if (col.name === "action") {
      return {
        ...col,
        header: "Action",
        minWidth: 100,
        defaultFlex: 1,
        textAlign: "center",
        render: ({ data }: any) => {
          const showMenu = menuVisibility[data.id];
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onMouseEnter={() => setMenuVisibility({ [data.id]: true })}
              onMouseLeave={() => setMenuVisibility({ [data.id]: false })}
            >
              <div style={{ textAlign: "center" }}>
                <button
                  className="action_btn"
                  style={{ backgroundColor: "transparent", cursor: "pointer" }}
                  title=""
                >
                  <Link
                    to={`/sales/line-view/${data?.saleId}`}
                    className="edit-btn"
                  >
                    <img src="/images/action_edit_new.svg" alt="View" />
                  </Link>
                </button>
              </div>
            </div>
          );
        },
        sortable: false,
        visible: isColumnVisible,
      };
    }
    if (col.name === "action new") {
      return {
        ...col,
        header: "Action",
        minWidth: 150,
        maxWidth: 250,
        defaultFlex: 1,
        textAlign: "center",
        render: ({ data }: any) => {
          const showMenu = menuVisibility[data.id];
          return (
            <div className="sales-action-New">
              <button
                className="action-btn"
                onClick={() => handleClickViewButton(data?.id)}
              >
                <img src="images/action_eye.svg" alt="View" />
              </button>
              <button
                className="action-btn"
                onClick={() => handleClickEditButton(data?.id)}
              >
                <img src="images/action_edit_new.svg" alt="Edit" />
              </button>
              <button
                className="action-btn"
                onClick={() => handleDeleteOpen(data?.id)}
              >
                <img src="images/delete_icon_black.svg" alt="Delete" />
              </button>
            </div>
          );
        },
        sortable: false,
        visible: isColumnVisible,
      };
    }

    // Default column rendering
    return {
      ...col,
      render: ({ data }: any) => {
        let value = col.name.includes(".")
          ? col.name
              .split(".")
              .reduce((acc: any, part: any) => acc && acc[part], data) || "-"
          : data[col.name] || "-";

        if (col.name === "dateTime") {
          // const formattedDate = moment(data.dateTime).format("MM/DD/YYYY");
          value = (
            <>
              <span>{formatDateNew(data.dateTime)}</span>
            </>
          );
        }

        if (col.name === "idVerified") {
          value = data.idVerified ? "Yes" : "No";
        } else if (col.name === "autoPay") {
          value = data.autoPay ? "Yes" : "No";
        } else if (col.name === "firstPayment") {
          value = "$" + data.firstPayment;
        }

        return (
          <span
            style={{ cursor: "pointer" }}
            onDoubleClick={() => copyToClipboard(value)}
            title="Double click to copy"
          >
            {value}
          </span>
        );
      },
      header: (
        <RenderHeader
          column={col}
          dataSource={dataSource}
          onFilterChange={handleFilterChange}
          filters={filters}
          setFilters={setFilters}
          // fetchFilteredData={fetchFilteredData}
          onSortChange={handleSortChange}
          sortBy={sortBy}
          sortOrder={sortOrder}
          selectedSalesType={selectedSalesType}
        />
      ),
      sortable: true,
      visible: isColumnVisible,
    };
  });

  return (
    <>
      <ReactDataGrid
        idProperty="id"
        columns={enhancedColumns.filter((col: any) => col.visible !== false)}
        dataSource={dataSource}
        style={gridStyle}
        onSortInfoChange={(sortInfo: any) => {
          const { name, dir } = sortInfo;
          handleSortChange(name, dir);
        }}
      />
      {copyMessage && <div className="copy-message">{copyMessage}</div>}

      <style>
        {`
        .copy-message {
          position: fixed;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          background-color: #333;
          color: #fff;
          padding: 8px 12px;
          border-radius: 4px;
          font-size: 14px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          z-index: 1000;
          opacity: 0.9;
        }
        `}
      </style>
    </>
  );
};

export default CustomDataTable;
