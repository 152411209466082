import { Navigate } from "react-router-dom";

import { useContext, useState } from "react";
import SideMenu from "./components/common/SideMenu";
import { getRole, getToken } from "./utils/common";
import AppContext from "./context/AppProvider";

export function AdminAuthenticatedApp({ children }: any) {
  const { mobileToggle, setMobileToggle, setProfileData } =
    useContext(AppContext);
  const [activeMenu, setActiveMenu] = useState(false);
  const token = getToken();
  const role = getRole();

  console.log("setProfileData", setProfileData);

  if (!token) {
    return <Navigate to="/" replace={true} />;
  }

  if (role === "admin") {
    return (
      <>
        <div className="admin-dashboard">
          <SideMenu activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
          <div
            className={`main-dashboard  subadmin-dashboard ${
              activeMenu && "dashboard-active"
            } }`}
          >
            {children}
          </div>
        </div>
      </>
    );
  } else {
    return <Navigate to="/" />;
  }
}
