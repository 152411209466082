import { NavLink } from "react-router-dom";
import AppContext from "../../context/AppProvider";
import { useContext, useEffect, useState } from "react";
import { getProfileData } from "../../config/api";

const WebSideMenu = ({
  activeMenu,
  isHovered,
  setIsHovered,
  setActiveMenu,
}: any) => {
  const { mobileToggle, setMobileToggle, setProfileData, profileData } =
    useContext(AppContext);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const res: any = await getProfileData();
        setProfileData(res?.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    getData();
  }, []);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`dashboard-menu ${!isHovered ? "dashboard-active" : ""} ${
        mobileToggle ? "menu-active" : ""
      }`}
    >
      <div className="logo">
        <div className="logo-img">
          <a>
            <img src="images/Logo.png" alt="Logo image" />
          </a>
        </div>
        <div
          className="tab-menu-bar"
          onClick={() => setActiveMenu(!activeMenu)}
        >
          <a className="barBtn" style={{ cursor: "pointer" }}>
            <img src="images/bar_icon.svg" alt="" />
          </a>
          <a className="closeBtn-mobile" onClick={() => setMobileToggle(false)}>
            <img src="images/times_icon.svg" alt="" />
          </a>
        </div>
      </div>
      <div className="dashboard-options">
        <ul>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/calculator"
            >
              <span className="menu-icon">
                <img src="images/sales_icon.svg" alt="" />
              </span>
              <span>Calculator</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/website-incidents"
            >
              <span className="menu-icon">
                <img src="images/issue_icon.svg" alt="" />
              </span>
              <span>Incidents</span>
            </NavLink>
          </li>
          {profileData?.salesPermissions &&
            profileData?.salesPermissions?.length > 0 && (
              <>
                {Array.isArray(profileData?.salesPermissions) &&
                (profileData?.salesPermissions.length === 0 ||
                  (profileData?.salesPermissions.length === 1 &&
                    profileData?.salesPermissions[0] === "View")) ? null : (
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/sale-entry"
                    >
                      <span className="menu-icon">
                        <img src="images/sales_entry_icon.svg" alt="" />
                      </span>
                      <span>Sale Entry</span>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to="/website-sales"
                  >
                    <span className="menu-icon">
                      <img src="images/sales_icon_new.svg" alt="" />
                    </span>
                    <span>Sales</span>
                  </NavLink>
                </li>
              </>
            )}

          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/setting-profile"
            >
              <span className="menu-icon">
                <img src="images/setting_icon.svg" alt="" />
              </span>
              <span>Settings</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WebSideMenu;
